import { v4 } from "uuid";
import { Lab } from "../../../../types/Entities/Lab";
import { LabPricesResponse } from "../../../../types/LabPricesResponse";
import {
  ORDER_COST,
  PHYSICIAN_REQUISITION,
  SERVICE_FEE,
} from "../Payment/PaymentDetails";

interface Price {
  test: string;
  price: number;
  walkInPrice: number;
  drawFee: number;
}
export interface PricedLabs extends Lab {
  totalPrice: number;
  priceDetails: Price[];
  labUniqueId: string;
}

// This util function merges the tests and labs data and returns the priced response
export const getPricedResponse = (tests: LabPricesResponse[], labs: Lab[]) => {
  const result: Array<PricedLabs> = [];
  let priceDetails: Array<Price> = [];
  // Iterate over each lab
  labs.forEach((lab) => {
    priceDetails = [];
    const labSlug = lab.slug;
    let totalPrice = 0;
    let drawFee = 0;

    const serviceCost = SERVICE_FEE + PHYSICIAN_REQUISITION + ORDER_COST;
    tests.forEach((test) => {
      test.prices.forEach((priceObj) => {
        if (priceObj.labProvider === labSlug) {
          if (!drawFee) {
            drawFee = Number(priceObj.drawFee);
          }
          totalPrice += Number(priceObj.price);
          priceDetails.push({
            test: test.testName,
            price: Number(priceObj.price),
            walkInPrice: Number(priceObj.walkInPrice),
            drawFee: Number(priceObj.drawFee),
          });
        }
      });
    });

    totalPrice += drawFee + serviceCost;

    if (totalPrice > 0) {
      result.push({
        ...lab,
        labUniqueId: v4(),
        totalPrice,
        priceDetails,
      });
    }
  });

  return result;
};
