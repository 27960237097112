import React, { useContext, useEffect, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { useDispatch, useSelector } from "react-redux";
import { OverviewPopupTemplate } from "../../../Components/Common/OverviewPopup";
import { Button } from "../../Common/Button";
import { PopupLayerContext } from "../../Common/PopupLayer";
import { RadioButtonsList } from "../../Common/RadioButtonsList";
import "./style.scss";
import { ProviderTask } from "../../../../types/Provider";
import { getMomentDate } from "../../../../utils/get-date-pretty";
import { StyledSelectCreatable } from "../../Common/StyledSelect";
import { AssignTaskModalPopupGenerator } from "../AssignTaskModalPopup";
import SignTheDocumentPopupGenerator from "../../Common/SignTheDocumentPopup";
import { downloadDocument } from "../../../../utils/download-document";
import { useGetPatientByIdQuery } from "../../../../features/api/patients";
import { LoadingBlock } from "../../Common/LoadingBlock";
import { useGetAllEmployeesQuery } from "../../../../features/api/employees";
import { useGetTasksQuery } from "../../../../features/api/accutane";
import { Employee } from "../../../../types/Employee";
import { USER_ROLES } from "../../../../types/Main";

interface Props {
  onClose: () => void;
}

interface TitleExtraContent {
  documentTitle: string;
  onAssign: () => void;
  documentUrl: string;
  disableFeatures?: boolean;
  isEsignature?: boolean;
}

export interface Task {
  label: string;
  value: string;
}

export interface DocumentsForm {
  currentTask: string;
  comment: Task | string;
}

interface DocumentOverviewProps {
  userId: string;
  onClose: () => void;
  url: string;
  documentId: string;
  employeeId: string;
  disableFeatures?: boolean;
  isEsignature: boolean;
}

function getDocumentExtencionByUrl(url: string) {
  return url.split(".").pop();
}

const taskSelectGroup = (data: any) => (
  <div>
    <span>{data.label.toUpperCase()}</span>
  </div>
);

const documentSelectOptions = [
  {
    label: "all tasks",
    options: [
      {
        label: "Reviewed blood work. All within normal limits",
        value: "bloodWorkResultsNormal",
      },
      {
        label: "Confirmed negative at-home urine pregnancy test",
        value: "confirmedNegativeUrinePregnancyTestAtHome",
      },
      {
        label: "Confirmed negative pregnancy test (urine HCG)",
        value: "reviewedNegativeUrineHCGPregnancyTest",
      },
      {
        label: "Confirmed negative pregnancy test (blood HCG)",
        value: "reviewedNegativeBloodHCGPregnancyTest",
      },
      {
        label:
          "Reviewed blood work. All within normal limits. Confirmed negative pregnancy test",
        value: "reviewedBloodWork&PregnancyTest",
      },
    ],
  },
];

const governmentIdDocumentSelectOptions = [
  {
    label: "Government Issued Id",
    options: [
      {
        label: "Reviewed ID and verified that name and DOB matches",
        value: "reviewedGovernmentIssuedId",
      },
    ],
  },
];

const getAssignedEmployeeFullName = (
  allEmployees: Employee[],
  assignedToId: string
) => {
  const employee = allEmployees.find(
    (_employee) => _employee.id === assignedToId
  );
  if (!employee) return "-";

  return `${employee.firstName} ${employee.lastName}${
    employee.role === USER_ROLES.PROVIDER ? `, ${employee.title}` : ""
  }`;
};

function DocumentTaskForm({
  task,
  employees,
  userId,
  documentId,
  employeeId,
}: {
  task: ProviderTask;
  employees: Employee[];
  userId: string;
  documentId: string;
  employeeId: string;
}) {
  const { showPopup } = useContext(PopupLayerContext);
  const [documentsForm, setFormField] = useState<DocumentsForm>({
    currentTask: task.id,
    comment: "",
  });
  const [selectOptions, setSelectOptions] = useState(documentSelectOptions);
  const [selectOptionLoading, setOptionsLoading] = useState(false);

  const handleCommentChange = (comment: { label: string; value: string }) => {
    setFormField({ ...documentsForm, comment });
  };

  const onSubmit = () => {
    showPopup(
      SignTheDocumentPopupGenerator(
        userId,
        documentId,
        employeeId,
        documentsForm
      )
    );
  };

  return (
    <div className="overview-popup__item-wrap">
      <p className="overview-popup__overview-form__item__extra--bold">
        {task.message}
      </p>
      <p className="overview-popup__overview-form__item__extra">
        Assigned to{" "}
        {getAssignedEmployeeFullName(employees, task.assignedTo?.id)}, added on{" "}
        {getMomentDate(task.createdAt).format("MM/DD/YYYY")}
      </p>
      {task.compositeKey.includes(employeeId) ? (
        <div className="overview-popup__overview-form__item__comment-wrapper">
          <StyledSelectCreatable
            id={task.id}
            name={task.id}
            options={
              task.type === "governmentIssuedId"
                ? governmentIdDocumentSelectOptions
                : selectOptions
            }
            value={documentsForm.comment}
            isDisabled={!task.compositeKey.includes(employeeId)}
            onChange={(comment: { label: string; value: string }) => {
              setOptionsLoading(true);
              setTimeout(() => {
                handleCommentChange(comment);
                setOptionsLoading(false);
              }, 0);
            }}
            filterOption={(option: any, searchText: any) => {
              if (!searchText) {
                return true;
              }
              return option.label
                .toLowerCase()
                .includes(searchText.toLowerCase());
            }}
            onCreateOption={(option) => {
              setOptionsLoading(true);
              const newTask = { label: option, value: option };
              setTimeout(() => {
                handleCommentChange(newTask);
                setOptionsLoading(false);
              }, 0);
            }}
            formatGroupLabel={taskSelectGroup}
            placeholder="Enter your comment"
            createOptionPosition="first"
            isSearchable
            disabled={selectOptionLoading}
          />
          <Button
            text="Submit & Sign"
            className="overview-popup__overview-form__item__comment-wrapper__submit"
            onClick={onSubmit}
            disabled={!documentsForm.comment}
            size="small"
          />
        </div>
      ) : null}
    </div>
  );
}

function DocumentOverviewPopup({
  userId,
  onClose,
  url,
  documentId,
  employeeId,
  disableFeatures = false,
  isEsignature = false,
}: DocumentOverviewProps) {
  const allEmployeesQuery = useGetAllEmployeesQuery(null, {
    skip: disableFeatures,
  });
  const tasksQuery = useGetTasksQuery(
    {
      patientId: userId,
      documentId,
    },
    {
      skip: disableFeatures,
    }
  );
  const [pages, setPage] = useState({
    currentPage: 1,
    totalPages: 0,
  });
  const [selectedTask, setSelectedTask] = useState<string>();

  const getTasksOptions =
    !allEmployeesQuery.data || !tasksQuery.data
      ? null
      : tasksQuery.data
          .filter(
            (task: ProviderTask) =>
              task.status !== "COMPLETED" && task.status !== "DELETED"
          )
          .map((task: ProviderTask) => ({
            label: task.message,
            value: task.id,
            key: task.id,
            disabled: !task.compositeKey.includes(employeeId),
            extra: (
              <DocumentTaskForm
                userId={userId}
                employeeId={employeeId}
                documentId={documentId}
                task={task}
                employees={allEmployeesQuery.data as Employee[]}
              />
            ),
          }));

  // const handleSelectOption = (option: string) => {
  //   setFormField({ comment: "", currentTask: option });
  // };

  const documentExtencion = getDocumentExtencionByUrl(url);

  // eslint-disable-next-line prefer-spread
  const documentPages = Array.apply(null, Array(pages.totalPages))
    .map((x, i) => i + 1)
    .map((page) => <Page pageNumber={page} />);

  return (
    <div className="overview-popup">
      <div className="overview-popup__document-wrapper">
        {documentExtencion === "pdf" && (
          <Document
            file={url}
            onLoadSuccess={({ numPages }) => {
              setPage({ ...pages, totalPages: numPages });
            }}
            onLoadError={(...err) => {
              console.log("err", err);
            }}
            onSourceError={(...err) => {
              console.log("source err", err);
            }}
          >
            {documentPages}
          </Document>
        )}
        {documentExtencion === "jpeg" && (
          <img
            className="overview-popup__document-wrapper__image"
            src={url}
            alt="document-preview"
          />
        )}
        {isEsignature && (
          <div style={{ width: "100%", height: "100%", position: "relative" }}>
            <iframe
              src={url}
              frameBorder={0}
              style={{
                overflow: "hidden",
                overflowX: "hidden",
                overflowY: "hidden",
                height: "100%",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
              height="100%"
              width="100%"
            />
          </div>
        )}
      </div>
      <div className="overview-popup__overview-form">
        {/* <div className="overview-popup__overview-form__item"> */}
        {/*  <p className="overview-popup__overview-form__item__title"> */}
        {/*    Latest activity */}
        {/*  </p> */}
        {/*  <div className="overview-popup__overview-form__item__content"> */}
        {/*    <div className="overview-popup__overview-form__item__content-single"> */}
        {/*      <p className="overview-popup__overview-form__item__content-single__activity-title"> */}
        {/*        Tamazine Davison (Admin) */}
        {/*      </p> */}
        {/*      <p className="overview-popup__overview-form__item__content-single__activity-content"> */}
        {/*        Uploaded the file on 2022.06.30, 5:30 PM */}
        {/*      </p> */}
        {/*    </div> */}
        {/*    <div className="overview-popup__overview-form__item__content-single"> */}
        {/*      <p className="overview-popup__overview-form__item__content-single__activity-title"> */}
        {/*        Tamazine Davison (Admin) */}
        {/*      </p> */}
        {/*      <p className="overview-popup__overview-form__item__content-single__activity-content"> */}
        {/*        Assigned a task to Joel Spitz, MD 2022.06.30, 5:30 PM */}
        {/*      </p> */}
        {/*    </div> */}
        {/*  </div> */}
        {/* </div> */}
        <div className="overview-popup__overview-form__item">
          <p className="overview-popup__overview-form__item__title">
            Outstanding tasks
          </p>
          {!disableFeatures &&
            getTasksOptions
              ?.filter(({ disabled }) => !disabled)
              .map(({ extra }) => extra)}
          {!disableFeatures &&
            getTasksOptions
              ?.filter(({ disabled }) => disabled)
              .map(({ extra }) => extra)}
        </div>
      </div>
    </div>
  );
}

function TitleExtraContent({
  documentTitle,
  onAssign,
  documentUrl,
  disableFeatures = false,
  isEsignature = false,
}: TitleExtraContent) {
  return (
    <div className="extra-content">
      {!disableFeatures && (
        <Button
          text="Assign a task"
          view="secondary"
          onClick={onAssign}
          size="small"
          className="extra-content__button"
        />
      )}
      {!isEsignature && (
        <Button
          text="Download"
          view="secondary"
          size="small"
          onClick={downloadDocument(documentUrl, documentTitle)}
          className="extra-content__button"
        />
      )}
      {/* <div className="extra-content__dots-wrapper"> */}
      {/*  <div className="table-body-row-action-button"> */}
      {/*    <div className="table-body-row-action-button-dot" /> */}
      {/*    <div className="table-body-row-action-button-dot" /> */}
      {/*    <div className="table-body-row-action-button-dot" /> */}
      {/*  </div> */}
      {/* </div> */}
    </div>
  );
}

function DocumentOverviewPopupGenerator(
  popupTitle: string,
  userId: string,
  documentId: string,
  url: string,
  employeeId: string,
  disableFeatures?: boolean,
  isEsignature?: boolean
) {
  return function render({ onClose }: Props) {
    const { showPopup } = useContext(PopupLayerContext);
    const patientQuery = useGetPatientByIdQuery(userId);

    if (!patientQuery.data) {
      return <LoadingBlock />;
    }

    if (!url) {
      return <div />;
    }

    if (isEsignature) {
      return (
        <OverviewPopupTemplate
          title={popupTitle}
          onClose={onClose}
          extra={
            <TitleExtraContent
              documentTitle={popupTitle}
              onAssign={() => null}
              documentUrl={url}
              disableFeatures={disableFeatures}
              isEsignature
            />
          }
        >
          <DocumentOverviewPopup
            userId={userId}
            onClose={onClose}
            url={url}
            documentId={documentId}
            employeeId={employeeId}
            disableFeatures={disableFeatures}
            isEsignature
          />
        </OverviewPopupTemplate>
      );
    }

    if (disableFeatures) {
      return (
        <OverviewPopupTemplate
          title={popupTitle}
          onClose={onClose}
          extra={
            <TitleExtraContent
              documentTitle={popupTitle}
              onAssign={() => null}
              documentUrl={url}
              disableFeatures={disableFeatures}
            />
          }
        >
          <DocumentOverviewPopup
            userId={userId}
            onClose={onClose}
            url={url}
            documentId={documentId}
            employeeId={employeeId}
            disableFeatures={disableFeatures}
            isEsignature={false}
          />
        </OverviewPopupTemplate>
      );
    }

    const onAssign = () => {
      if (patientQuery.data) {
        showPopup(
          AssignTaskModalPopupGenerator(
            popupTitle,
            patientQuery.data.fullName,
            documentId,
            userId,
            employeeId
          )
        );
      }
    };

    return (
      <OverviewPopupTemplate
        title={popupTitle}
        onClose={onClose}
        extra={
          <TitleExtraContent
            documentTitle={popupTitle}
            onAssign={onAssign}
            documentUrl={url}
          />
        }
      >
        <DocumentOverviewPopup
          userId={userId}
          onClose={onClose}
          url={url}
          documentId={documentId}
          employeeId={employeeId}
          isEsignature={false}
        />
      </OverviewPopupTemplate>
    );
  };
}

export default DocumentOverviewPopupGenerator;
