import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { NotificationLayerContext } from "../NotificationLayer";
import { PatientDashboardItem } from "../../../../types/Dashboard";
import { useSetFlagMutation } from "../../../../features/api/patients";
import { getSessionState } from "../../../../features/session";
import { ReactComponent as UnpinIcon } from "../../../../Assets/NewIcons/pin.svg";
import { ReactComponent as PinIcon } from "../../../../Assets/NewIcons/unpin.svg";
import { EMPLOYEE_ROLES } from "../../../../types/Employee";
import { USER_ROLES } from "../../../../types/Main";
import { wait } from "../../../../utils/wait";
import { TableOperation } from "../../../../types/Table/TableOperation";

type RoleKeyMap = {
  [k in USER_ROLES]: string;
};

const mapRoleToKey: RoleKeyMap = {
  [USER_ROLES.PROVIDER]: "Provider",
  [USER_ROLES.CARE_COORDINATOR]: "CareCoordinator",
  [USER_ROLES.ADMINISTRATOR]: "Administrator",
  [USER_ROLES.ENROLLMENT_COORDINATOR]: "NO_PIN_BUTTON",
  [USER_ROLES.PATIENT]: "NO_PIN_BUTTON",
};

export function PinButton({
  payload,
  employeeType,
  updateTable,
}: {
  payload: PatientDashboardItem;
  employeeType: EMPLOYEE_ROLES;
  updateTable: (
    operation: TableOperation<PatientDashboardItem>
  ) => Promise<void>;
}) {
  const { showSuccess, showError } = useContext(NotificationLayerContext);
  const [togglePin] = useSetFlagMutation();
  const { userId } = useSelector(getSessionState);

  const previousPinnedFlag = `pinnedBy${mapRoleToKey[employeeType]}`;
  const pinnedFlag = `pinnedBy${mapRoleToKey[employeeType]}_${userId}`;
  const pinned =
    !!payload.flags[pinnedFlag] || !!payload.flags[previousPinnedFlag];

  const pinPatient = async () => {
    updateTable({
      operation: (list: PatientDashboardItem[]) => {
        const listWithoutPatient = list.filter(
          ({ patientId }) => patientId !== payload.patientId
        );
        payload.flags[pinnedFlag] = !pinned;
        const pinnedPatients = listWithoutPatient.filter(
          ({ flags }) => flags[pinnedFlag]
        );
        const unpinnedPatients = listWithoutPatient.filter(
          ({ flags }) => !flags[pinnedFlag]
        );
        return [...pinnedPatients, payload, ...unpinnedPatients];
      },
    });

    const response: any = await togglePin({
      patientId: payload.patientId,
      flag: pinnedFlag,
      state: !pinned,
    });

    if (response.error) {
      showError({
        title: "Something went wrong...",
        description: `Unable to ${pinned ? "un" : ""}pin patient`,
      });
      throw new Error(`Unable to ${pinned ? "un" : ""}pin patient`);
    }

    // If unpinning, also set the previousPinnedFlag to false
    if (pinned && payload.flags[previousPinnedFlag]) {
      const previousFlagResponse: any = await togglePin({
        patientId: payload.patientId,
        flag: previousPinnedFlag,
        state: false,
      });
      if (previousFlagResponse.error) {
        showError({
          title: "Something went wrong...",
          description: `Unable to unpin patient from the old flag`,
        });
        throw new Error(`Unable to unpin patient from the old flag`);
      }
    }

    showSuccess({
      title: "Success!",
      description: `You've successfully ${pinned ? "un" : ""}pinned a patient!`,
    });
  };
  return (
    <div
      className="patients-table__action-button"
      onClick={(e) => {
        e.stopPropagation();
        pinPatient();
      }}
      data-tooltip={pinned ? "Unpin" : "Pin"}
    >
      {pinned ? <UnpinIcon /> : <PinIcon />}
    </div>
  );
}
