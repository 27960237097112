import React, { useEffect } from "react";
import FindLab from "../../../Common/FindLab";
import { usePregnancyWorkStepper } from "../../../../../contexts/pregnancy-work";
import { Lab } from "../../../../../types/Entities/Lab";

export default function FindLabWrapper() {
  const { handleNext, pregnancyWork, setIsNextDisabled, updateValues } =
    usePregnancyWorkStepper();

  useEffect(() => {
    setIsNextDisabled(true);
  }, []);

  const handleChange = (value: Lab) => {
    updateValues({ selectedLab: value });
    setIsNextDisabled(false);
    handleNext();
  };

  return (
    <FindLab
      onLabSelected={(value) => handleChange(value)}
      defaultZipCode={pregnancyWork.patient?.shippingInfo?.zipCode}
      state={pregnancyWork?.patient?.shippingInfo?.state}
      taskId={pregnancyWork?.task?.id}
    />
  );
}
