import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isNull } from "util";
import { getSessionState, setNewMsgsCount } from "../../../../features/session";
import {
  DoctorGreetingBanner,
  TaskColors,
} from "../../../NewComponents/Common/DoctorGreetingBanner";
import "./style.scss";
import { useGetEmployeeQuery } from "../../../../features/api/employees";
import { CareCoordinator } from "../../../../types/Employee";
import { PatientsTableTabs } from "../../../NewComponents/Common/PatientsTableElastic/tableTabs";
import {
  getPatientDashboardForCareCoordinatorFilters,
  getPatientDashboardForCareCoordinatorFiltersInactive,
} from "./filters";
import { PatientsTableElastic } from "../../../NewComponents/Common/PatientsTableElastic";
import { PatientDashboardItem } from "../../../../types/Dashboard";
import { patientDashboardForCareCoordinatorSort } from "./sortings";
import { Stylings } from "../../../../types/Table/TableBodyPayload";
import { getTwilioState } from "../../../../features/twilio";
import { patientDashboardForCareCoordinatorOrder } from "./orders";
import { getAllChannelMetadata } from "../../../../utils/patient/pubnubChatCount";
import { tableColumns } from "./columns";
import { HoneydewAPI } from "../../../../services/honeydew-api";

function getStyleForDashboard(employeeId: string) {
  return (payload: PatientDashboardItem, currentFilter?: string) => {
    switch (true) {
      case currentFilter === "New treatment plans":
        return Stylings.Blue;
      case currentFilter === "Miscellaneous tasks":
        return Stylings.Orange;
      case payload.followUp &&
        payload.followUp.isTreatmentPlanSet &&
        !payload.followUp.isPrescriptionSubmitted:
        return Stylings.Blue;
      case payload.tasks?.includes(employeeId):
        return Stylings.Orange;
      default:
        return Stylings.Base;
    }
  };
}

export function PatientsPageForCareCoordinator() {
  const dispatch = useDispatch();
  const { initialized } = useSelector(getTwilioState);
  const [newMessagesCount, setNewMessagesCount] = useState<number | null>(null);
  const history = useHistory();
  const { userId: careCoordinatorId, newMsgsCount } =
    useSelector(getSessionState);
  const [tab, setTab] = useState(0);
  const [tableParams, setTableParams] = useState<
    { filters: any; sort: any; order: any }[]
  >([]);
  const careCoordinatorQuery = useGetEmployeeQuery(
    careCoordinatorId as string,
    {
      skip: !careCoordinatorId,
    }
  );
  const careCoordinator = careCoordinatorQuery.data as
    | CareCoordinator
    | undefined;

  const [filterArray, setFilterArray] = useState([]);
  const [filteredChat, setFilteredChat] = useState<any>([]);
  const [patientIds, setPatientIds] = useState<any>([]);

  useEffect(() => {
    const role = careCoordinatorQuery?.currentData?.role;
    if (filterArray.length === 0 && role) {
      let newFilterArray;
      switch (role) {
        case "care-coordinator":
          newFilterArray = getPatientDashboardForCareCoordinatorFilters(
            careCoordinatorId as string
          );
          break;
        default:
          break;
      }
      if (newFilterArray) {
        setFilterArray(newFilterArray);
      }
    }
  }, [filterArray, careCoordinatorQuery, careCoordinatorId]);

  // const filterChatBydata = async (data: any) => {
  //   // Modify the existing data by adding lastUpdated and authorIds attributes
  //   const updatedChats = data.map((item: any) => {
  //     const {
  //       _source: { chat, latestChatTimestamp },
  //     } = item;

  //     if (chat && Array.isArray(chat)) {
  //       // Extract userId from each chat entry and remove duplicates
  //       const authorIds = [...new Set(chat.map((entry: any) => entry.userId))];

  //       // Add authorIds and lastUpdated to the existing data item
  //       item._source.authorIds = authorIds; // Adding authorIds to the existing object
  //       item._source.lastUpdated = latestChatTimestamp; // Adding lastUpdated to the existing object
  //     }

  //     return item; // Return the updated object
  //   });
  //   // Dispatch updated chat count based on the filtered result
  //   dispatch(setNewMsgsCount(updatedChats.length));
  // };

  useEffect(() => {
    if (filterArray.length > 0) {
      const fetchData = async () => {
        const { filter } = filterArray[0];
        let mustQuery = filter.must;
        if (mustQuery instanceof Array) {
          mustQuery = [...mustQuery];
        } else if (mustQuery instanceof Object) {
          mustQuery = [mustQuery];
        } else {
          mustQuery = [];
        }
        try {
          const query = {
            query: {
              from: 0,
              size: 10000,
              query: {
                // when you comment this query the code starts working with extra records
                function_score: {
                  query: {
                    bool: {
                      ...filter,
                    },
                  },
                },
              },
            },
          };
          const results = await HoneydewAPI.dashboard.queryDashboardItems(
            query
          );
          const filteredChats = results.hits.filter((result: any) => {
            if (!result._source.subscription) {
              return true;
            }

            return result._source.subscription.membership === true;
          });
          setFilteredChat(filteredChats);
          setPatientIds(filteredChats.map((chat) => chat._source.patientId));
        } catch (error) {
          console.log("error in fetching table data", error);
        }
      };
      fetchData();
    }
  }, [filterArray]);

  useEffect(() => {
    // if (!newMsgsCount) {
    if (filteredChat.length && !newMsgsCount && patientIds.length > 0) {
      const fetchData = async () => {
        try {
          const pubNubSubscribeKey =
            await HoneydewAPI.chats.pubNubSubscribeKey();
          const metadataFlags = await getAllChannelMetadata(
            careCoordinatorId,
            patientIds,
            pubNubSubscribeKey
          );
          const uniqueMetadataFlags = metadataFlags.reduce((acc, obj) => {
            acc[obj.id] = obj;
            return acc;
          }, {});
          const uniqueMetadataArray = Object.values(uniqueMetadataFlags);

          const newMsgs = uniqueMetadataArray.filter(
            (obj) => obj.custom[careCoordinatorId] === false
          );
          const filteredResult = filteredChat.filter((chat) => {
            const chatMetaData = newMsgs.find(
              (metaData) => metaData.id === chat._source.patientId
            );
            if (chatMetaData) {
              return true;
            }
            return false;
          });
          dispatch(setNewMsgsCount(filteredResult.length));
          // setNewMessagesCount(newMsgs?.length);
          // dispatch(setNewMsgsCount(newMsgs?.length));
        } catch (error) {
          console.error("Error fetching metadata flags:", error);
        }
      };

      fetchData();
    }
    // }
  }, [filteredChat, patientIds]);

  useEffect(() => {
    const currentIndex = localStorage.getItem("currentIndex");
    const currentIndexFormatted = JSON.parse(currentIndex);
    if (!isNull(currentIndexFormatted)) {
      setTab(currentIndexFormatted.currentTab);
    }
  }, []);

  useEffect(() => {
    if (!careCoordinatorId) return;
    setTableParams([
      {
        filters:
          getPatientDashboardForCareCoordinatorFilters(careCoordinatorId),
        sort: patientDashboardForCareCoordinatorSort(),
        order: patientDashboardForCareCoordinatorOrder(careCoordinatorId),
      },
      {
        filters:
          getPatientDashboardForCareCoordinatorFiltersInactive(
            careCoordinatorId
          ),
        sort: [],
        order: [],
      },
    ]);
  }, [careCoordinatorId]);
  if (!careCoordinator) return null;

  function renderTable() {
    if (!tableParams[tab]) return null;
    switch (tab) {
      case 0:
        // Active
        return (
          <PatientsTableElastic<PatientDashboardItem>
            filters={tableParams[tab].filters}
            sort={tableParams[tab].sort}
            order={tableParams[tab].order}
            columns={tableColumns}
            stylizeRow={getStyleForDashboard(careCoordinator?.id as string)}
            onClick={(item, state) => {
              const myState = { currentSubTab: state, currentTab: tab };
              localStorage.setItem("currentIndex", JSON.stringify(myState));
              window.scrollTo(0, 0);
              history.push(`/${item.patientId}`);
            }}
          />
        );
      case 1:
        // Inactive
        return (
          <PatientsTableElastic<PatientDashboardItem>
            filters={tableParams[tab].filters}
            sort={tableParams[tab].sort}
            order={tableParams[tab].order}
            columns={tableColumns}
            onClick={(item, state) => {
              window.scrollTo(0, 0);
              const myState = { currentSubTab: state, currentTab: tab };
              localStorage.setItem("currentIndex", JSON.stringify(myState));
              history.push(`/${item.patientId}`);
            }}
          />
        );
      default:
        return null;
    }
  }

  const careCoordinatorName = careCoordinator
    ? [careCoordinator.firstName, careCoordinator.lastName]
    : null;

  const tabs = ["Your active patients", "Your inactive patients"];

  return (
    <div className="greeting-container">
      <DoctorGreetingBanner
        items={[
          {
            text: "New treatment plans",
            filter: getPatientDashboardForCareCoordinatorFilters(
              careCoordinator.id
            ).find(({ text }) => text === "New treatment plans")?.filter,
            color: TaskColors.LightBlue,
          },
          {
            text: "New messages",
            count: newMsgsCount ?? undefined,
            color: TaskColors.Green,
          },
          {
            text: "Miscellaneous tasks",
            filter: getPatientDashboardForCareCoordinatorFilters(
              careCoordinator.id
            ).find(({ text }) => text === "Miscellaneous tasks")?.filter,
            color: TaskColors.Orange,
          },
        ]}
        name={careCoordinatorName ? careCoordinatorName.join(" ") : null}
      />
      <div className="patients-table">
        <div className="patients-table__tabs">
          <PatientsTableTabs
            tabs={tabs}
            onChange={(state) => {
              setTab(state);
            }}
          />
        </div>
      </div>
      {renderTable()}
    </div>
  );
}
