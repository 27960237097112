import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CustomSurvey } from "../../../NewComponents/Common/CustomSurvey";
import { ReactComponent as MedicalBackgroundImage } from "../../../../Assets/NewIcons/medical-background.svg";
import "./style.scss";
import { Button } from "../../../NewComponents/Common/Button";
import { SkinImagesSurveyQuestion } from "./survey-questions/skin-images";
import { isEmpty } from "../../../../utils/is-empty";
import { ImageBlob } from "../../../../types/Base/ImageBlob";
import generateHash from "../../../../utils/generate-hash";
import { NotificationLayerContext } from "../../../NewComponents/Common/NotificationLayer";
import { sendSurveyEnded } from "../../../../shared/analytics";
import { HeightSurveyQuestion } from "./survey-questions/height";
import { WeightSurveyQuestion } from "./survey-questions/weight";
import { getSessionState } from "../../../../features/session";
import { MedicalBackgroundItem } from "../../../../types/Entities/Patient";
import { surveyValueToDB } from "../../../../utils/survey/survey-value-to-db";
import {
  useGetPatientByIdQuery,
  useSubmitMedicalBackgroundMutation,
} from "../../../../features/api/patients";
import { StyledSelect } from "../../../NewComponents/Common/StyledSelect";
import { SurveyQuestionTemplete } from "./survey-questions/index";
import { skinIssues } from "./skin-issues";
import { CustomSurveyStepperProvider } from "../../../../contexts/custom-survey";
import { SurveyTitle } from "../../../NewComponents/Common/OnBoardingTitle";

enum STATES {
  INIT,
  SURVEY_TYPE,
  SURVEY,
  COMPLETE,
}

const excludedQuestions = ["id", "height", "weight", "gender"];

export function SkinSurveyPage({
  isMedicalBackground,
  isChildAccount,
}: {
  isMedicalBackground: boolean;
  isChildAccount: boolean;
}) {
  const { showError } = useContext(NotificationLayerContext);
  const { activePatientId } = useSelector(getSessionState);
  const [submitMedicalBackground, { isSuccess, isError }] =
    useSubmitMedicalBackgroundMutation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState(STATES.INIT);
  const localStorageKey = `skin-survey-data:${activePatientId}`;
  const patientQuery = useGetPatientByIdQuery(activePatientId as string, {
    skip: !activePatientId,
  });
  const isFromAccutaneSource = window.localStorage.getItem(
    "isFromAccutaneSource"
  );

  // useEffect(() => {
  //   if (isMedicalBackground) setState(STATES.COMPLETE);
  // }, [isMedicalBackground]);

  useEffect(() => {
    if (state === STATES.COMPLETE) {
      sendSurveyEnded();
    }
  }, [state]);

  if (!activePatientId) return null;

  const generateSurveyQuestions = (subQuestions: any[] | undefined) => {
    const questions =
      subQuestions &&
      subQuestions.map((question: { value: string | number }) => ({
        isFilled: (data: { [x: string]: any }) =>
          !isEmpty(data[question.value]) || isMedicalBackground,
        Component: SurveyQuestionTemplete,
        question,
        isChildAccount,
        when: (data) =>
          !question.parentQuestion ||
          (data[question?.parentQuestion] &&
            (data[question?.parentQuestion] === question.parentAnswer ||
              (question?.isArrayOfAnswer &&
                data[question?.parentQuestion].includes(
                  question.parentAnswer
                )))) ||
          (question.parentTextAnwser &&
            data[question.parentQuestion] !== question.parentTextAnwser),
      }));

    let payload = {};
    questions.push(
      {
        isFilled: (data: { height: any }) =>
          !isEmpty(data.height) || isMedicalBackground,
        Component: HeightSurveyQuestion,
      },
      {
        isFilled: (data: { weight: any }) =>
          !isEmpty(data.weight) || isMedicalBackground,
        Component: WeightSurveyQuestion,
        onComplete: async (
          data: { [s: string]: unknown } | ArrayLike<unknown>
        ) => {
          const { height, weight, gender } = data;
          const skinSurvey: MedicalBackgroundItem[] = Object.entries(data)
            .filter(
              ([key, value]) =>
                value !== null && !excludedQuestions.includes(key)
            )
            .map(([key, value]) => {
              const questionType = questions.find(
                (q) => q?.question?.value === key
              )?.question?.questionType;
              return {
                id: key,
                questionType,
                ...surveyValueToDB(value),
              };
            });
          payload = {
            height,
            weight,
            sex: gender,
            skinSurvey,
          };
          localStorage.setItem("payload", JSON.stringify(payload));
        },
      },
      {
        isFilled: () => false,
        Component: SkinImagesSurveyQuestion,
      }
    );
    return questions;
  };

  const skinIssuesOptions = [
    {
      label: "Skin Issues",
      value: "skinIssueType",
      options: skinIssues.map((issue) => ({
        label: issue.label,
        value: issue.value,
        surveyQuestions: generateSurveyQuestions(issue.subQuestions),
      })),
    },
  ];

  function findSkinTypeByName(typeName) {
    return skinIssues.filter((issue) => issue?.value === typeName);
  }

  function getSurveyDataFromLocalStorage(key) {
    const data = JSON.parse(window.localStorage.getItem(key));
    return data || {};
  }

  const existingData = getSurveyDataFromLocalStorage(localStorageKey);

  function setSurveyDataInLocalStorage(key, data) {
    window.localStorage.setItem(key, JSON.stringify(data));
  }

  const [surveyQuestionsBySkinIssue, setSurveyQuestionsBySkinIssue] = useState(
    generateSurveyQuestions(findSkinTypeByName("acne")[0]?.subQuestions)
  );

  function skipSkinIssuesType(value) {
    const foundIssue = skinIssuesOptions[0].options.find(
      (issue) => issue.value === value
    );
    const surveyQuestions = foundIssue ? foundIssue.surveyQuestions : [];
    setSurveyQuestionsBySkinIssue(surveyQuestions);
    setState(STATES.SURVEY);
  }

  const generateImagesFilename = (images: ImageBlob[]) =>
    images.map((image) => {
      const extension = `${image.type.split("/").pop()}`;
      const filename = `${activePatientId}.${generateHash(10)}.${extension}`;
      return filename;
    });

  function existingCacheData(value) {
    if (!existingData || existingData.skinIssueType !== value) {
      const surveyData = { id: activePatientId, skinIssueType: value };
      setSurveyDataInLocalStorage(localStorageKey, surveyData);
    }
  }

  useEffect(() => {
    if (!existingData.skinIssueType) {
      existingCacheData("acne");
    }
  }, [surveyQuestionsBySkinIssue]);

  function updateSurveyQuestions(value: {
    surveyQuestions: React.SetStateAction<never[]>;
  }) {
    existingCacheData(value.value);
    setSurveyQuestionsBySkinIssue(value?.surveyQuestions);
  }

  function resetLocalStorage() {
    if (existingData) {
      const { id, skinIssueType } = existingData;
      const newData = { id, skinIssueType };
      setSurveyDataInLocalStorage(localStorageKey, newData);
    }
    setState(STATES.SURVEY);
  }

  function render() {
    switch (state) {
      case STATES.INIT:
        return (
          <div className="survey-page">
            <MedicalBackgroundImage className="survey-page__image" />
            <p className="survey-page__title">
              Let's fill in your medical background
            </p>
            <p className="survey-page__text paragraph-font--color">
              Don't worry - we'll make this quick!
            </p>
            <Button
              text="Okay, Let's go"
              onClick={() => {
                if (isFromAccutaneSource || isMedicalBackground) {
                  setState(STATES.SURVEY);
                } else if (existingData && existingData.skinIssueType) {
                  skipSkinIssuesType(existingData.skinIssueType);
                } else {
                  setState(STATES.SURVEY_TYPE);
                }
              }}
            />
          </div>
        );

      case STATES.SURVEY_TYPE:
        return (
          <div className="custom-survey">
            <div className="custom-survey__heading">
              <SurveyTitle>MEDICAL BACKGROUND</SurveyTitle>
            </div>
            <div className="survey-question survey-question--small">
              <p className="survey-question__title">
                Which skin issue would you like to address?
              </p>
              <p className="survey-question__descr paragraph-font--color" />
              <p className="edit-patient-details-popup__input-title">
                skin Issue
              </p>
              <StyledSelect
                id="skinIssue"
                name="skinIssue"
                options={skinIssuesOptions}
                defaultValue={findSkinTypeByName(
                  existingData?.skinIssueType || "acne"
                )}
                onChange={(value: any) => value && updateSurveyQuestions(value)}
                hideCrossIcon
              />
              <Button
                text="continue"
                onClick={() => {
                  resetLocalStorage();
                }}
                className="survey-question__button"
                disabled={isEmpty(surveyQuestionsBySkinIssue)}
              />
            </div>
          </div>
        );
      case STATES.SURVEY:
        return (
          <CustomSurveyStepperProvider>
            <CustomSurvey
              title="MEDICAL BACKGROUND"
              questions={surveyQuestionsBySkinIssue}
              showProgressBar
              data={{
                id: activePatientId,
              }}
              onComplete={async (_data) => {
                try {
                  setState(STATES.COMPLETE);
                  return true;
                } catch {
                  showError({
                    title: "Something went wrong",
                    description:
                      "Could not submit your survey for some reason. Please, try again later.",
                  });
                  return false;
                }
              }}
              localStorage={`skin-survey-data:${activePatientId}`}
              setState={setState}
              isFromAccutaneSource={isFromAccutaneSource !== null}
              isMedicalBackground={isMedicalBackground}
              miminumPage={0}
              customBack
            />
          </CustomSurveyStepperProvider>
        );
      case STATES.COMPLETE:
        return (
          <div className="survey-page">
            <MedicalBackgroundImage className="survey-page__image" />
            <p className="survey-page__title">
              All done! You are ready for your visit.
            </p>
            <p className="survey-page__text paragraph-font--color">
              Please check your email for a calendar invitation and the meeting
              details of your initial consultation.
            </p>
            <Button text="Great!" onClick={() => history.push("/")} />
          </div>
        );
      default:
        return null;
    }
  }

  return render();
}
