import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
import { log } from "console";
import { BloodWorkPopupSteps, renderStep } from "./steps";
import { BloodWorkContainer } from "./styles";
import { useBloodWorkStepper } from "../../../../contexts/blood-work";
import { ENV_CONFIG } from "../../../../config";
import { hasCustomButtons } from "../../../../utils/blood-work-stepper";
import StepperButtons from "../../Stepper/StepperButtons";
import { getSessionState } from "../../../../features/session";
import { useGetPatientByIdQuery } from "../../../../features/api/patients";
import { getLabOrder } from "../../../../services/honeydew-api/labs/get-lab-order";

interface Props {
  onClose: () => void;
}

const stripePromise = loadStripe(ENV_CONFIG.STRIPE_KEY);

export function BloodWorkForm({ onClose }: Props) {
  const {
    currentStep,
    handleBack,
    handleNext,
    isNextDisabled,
    setCloseFunction,
    updateValues,
    setCurrentStep,
  } = useBloodWorkStepper();
  const { activePatientId } = useSelector(getSessionState);
  const [loading, setLoading] = useState(false);
  const patientQuery = useGetPatientByIdQuery(activePatientId as string, {
    skip: !activePatientId,
  });

  const getTasks = async () => {
    try {
      setLoading(true);
      const task = await localStorage.getItem("bloodworkTask");
      if (task) {
        const parsedTask = JSON.parse(task);
        if (parsedTask.status === "ASSIGNED_MIGRATED") {
          setCurrentStep(BloodWorkPopupSteps.CHECKOUT_INSURANCE);
          updateValues({
            task: parsedTask,
            patient: parsedTask.patientId,
            placeOfService: "medical-office",
            paymentMethod: "with-insurance",
          });
        } else {
          const labOrder = await getLabOrder(parsedTask.id);

          const metadata = JSON.parse(labOrder.pdfMetadata);

          const needsFasting = !!Array.from(metadata.fields).find(
            (field) => field.field_name === "bloodSlipDetails.alertText"
          );

          if (!labOrder?.id && parsedTask.status === "IN_PROGRESS") {
            // this indicates that the task is in progress but is a migrated task

            setCurrentStep(BloodWorkPopupSteps.CHECKOUT_INSURANCE);
            updateValues({
              task: parsedTask,
              patient: patientQuery.data,
              placeOfService: "medical-office",
              paymentMethod: "with-insurance",
              needsFasting,
            });
          } else {
            updateValues({
              task: parsedTask,
              patient: patientQuery.data,
              labOrder,
              needsFasting,
            });

            if (parsedTask.status === "IN_PROGRESS") {
              const checkoutPage =
                labOrder.paymentMethod === "with-insurance"
                  ? BloodWorkPopupSteps.CHECKOUT_INSURANCE
                  : BloodWorkPopupSteps.CHECKOUT;
              setCurrentStep(checkoutPage);
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setCloseFunction(() => onClose);
    getTasks();
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <BloodWorkContainer container spacing={2} gap={2}>
        {!loading ? (
          <>
            {renderStep(currentStep)}
            {!hasCustomButtons(currentStep) && (
              <StepperButtons
                onBack={handleBack}
                onConfirm={handleNext}
                isConfirmDisabled={isNextDisabled}
                confirmText="Continue"
                backText="Back"
              />
            )}
          </>
        ) : (
          <>
            <Skeleton width="40%" />
            <Skeleton width="80%" />
            <Skeleton width="80%" />
            <Skeleton width="80%" />
          </>
        )}
      </BloodWorkContainer>
    </Elements>
  );
}
