import React, { useState } from "react";
import Joi from "joi";
import { Link } from "react-router-dom";
import {
  SurveyComponentProps,
  SurveyData,
} from "../../../../../types/CustomSurvey";
import { Button } from "../../../../NewComponents/Common/Button";
import { Button as MuiButton } from "../../../../NewComponents/Common/Material/Button";
import { Checkbox } from "../../../../NewComponents/Common/Checkbox";
import { TextInput } from "../../../../NewComponents/Common/TextInput";
import {
  sendSignUpEvent,
  sendSurveyQuestion,
} from "../../../../../shared/analytics";
import HowItWorks from "../how-it-works";
import { getFirstName } from "../../../../../utils/survey/get-first-name";
import { useCustomSurveyStepper } from "../../../../../contexts/custom-survey";

interface Data {
  email: string;
  pwd: string;
}

function surveyToComponent(data: SurveyData): Data {
  return {
    email: data.creds?.email || "",
    pwd: data.creds?.pwd || "",
  };
}

function componentToSurvey(value: Data, marketingConsentChecked: boolean) {
  return {
    creds: {
      email: value.email,
      pwd: value.pwd,
    },
    agreedToMarketingCampaign: marketingConsentChecked,
  };
}

export function SignUpSurveyQuestion({ data, onDone }: SurveyComponentProps) {
  const [currentData, setData] = useState(surveyToComponent(data));
  const { onBack } = useCustomSurveyStepper();
  const [telehealthConsentChecked, checkTelehealthConsent] = useState(false);
  const [marketingConsentChecked, checkMarketingConsent] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState("");
  const [passwordInvalid, setPasswordInvalid] = useState("");

  const { fullName, isChild } = data;

  function validateEmail() {
    const { error } = Joi.string()
      .email({ tlds: { allow: false } })
      .validate(currentData.email);
    if (error) {
      setEmailInvalid("Invalid email");
      return false;
    }

    return true;
  }

  function validatePassword() {
    const lowercaseLettersRegexp = /[a-z]/;
    const numbersRegexp = /\d/;
    if (
      !lowercaseLettersRegexp.test(currentData.pwd) ||
      !numbersRegexp.test(currentData.pwd) ||
      currentData.pwd.length < 8
    ) {
      setPasswordInvalid(
        "Password should be at least 8 characters long, contain at least one lower case letter and one number"
      );
      return false;
    }

    return true;
  }

  async function submit() {
    const isEmailValid = validateEmail();
    const isPasswordValid = validatePassword();
    if (!isEmailValid || !isPasswordValid) return;

    await onDone(componentToSurvey(currentData, marketingConsentChecked));
    sendSurveyQuestion("signUp");
    sendSignUpEvent();
  }

  return (
    <div className="survey-question__wrapper">
      <div className="survey-question survey-question--small">
        <p className="survey-question__title">
          {isChild
            ? `Great news. ${getFirstName(fullName)} is eligible for Honeydew!`
            : `${getFirstName(
                fullName
              )}, great news. You're eligible for Honeydew!`}
        </p>
        <p className="survey-question__descr paragraph-font--color">
          {isChild
            ? "Create your account to get started and schedule the initial consultation."
            : "Create your account to get started and schedule your initial consultation"}
        </p>
        <div className="survey-question__input">
          <TextInput
            id="email"
            name="email"
            onChange={(value) => {
              setData({
                ...currentData,
                email: value,
              });
              setEmailInvalid("");
            }}
            required
            value={currentData.email || ""}
            placeholder="jane@example.com"
            error={emailInvalid}
          />
        </div>
        <div className="survey-question__input">
          <TextInput
            id="password"
            name="password"
            onChange={(value) => {
              setData({
                ...currentData,
                pwd: value,
              });
              setPasswordInvalid("");
            }}
            required
            value={currentData.pwd || ""}
            placeholder="Password"
            type="password"
            error={passwordInvalid}
          />
        </div>
        <div className="sign-up__consent-link survey-question__input">
          <Checkbox
            onChange={(state) => checkTelehealthConsent(state)}
            checked={telehealthConsentChecked}
          >
            <>
              I agree with{" "}
              <a
                href="https://honeydewcare.com/Honeydew_Terms_of_Service.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Service
              </a>
              ,{" "}
              <a
                href="https://honeydewcare.com/Honeydew_Privacy_Policy.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              , and{" "}
              <a
                href="https://honeydewcare.com/Honeydew_Telehealth_Consent.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Telehealth Consent
              </a>
            </>
          </Checkbox>
        </div>
        <div className="sign-up__consent-link">
          <Checkbox
            onChange={(state) => checkMarketingConsent(state)}
            checked={marketingConsentChecked}
          >
            <>
              <p className="custom-checkbox__bold">Consent to receive SMS</p>
              By checking this box, you agree to receive recurring automated
              promotional and personalized marketing text messages (e.g. cart
              reminders) from Honeydew Care at the cell number used when signing
              up. Consent is not a condition of any purchase. Reply HELP for
              help and STOP to cancel. Msg frequency varies. Msg & data rates
              may apply. View{" "}
              <a
                href="https://attnl.tv/t/o3G/r/US"
                target="_blank"
                rel="noreferrer"
              >
                Terms
              </a>{" "}
              &{" "}
              <a href="https://attnl.tv/p/o3G" target="_blank" rel="noreferrer">
                Privacy
              </a>
            </>
          </Checkbox>
        </div>
        <Button
          text="let's go"
          onClick={submit}
          className="survey-question__button"
          disabled={
            !Object.values(currentData).every((value) => !!value) ||
            !telehealthConsentChecked
          }
        />
        <MuiButton
          text="Back"
          fullWidth
          view="secondary"
          onClick={onBack}
          sx={{
            marginTop: 2,
          }}
        />
        <div className="sign-up__log-in-proposal">
          Already have an account?{" "}
          <Link to="/login" className="sign-up__log-in-link link-font--color">
            Log in
          </Link>
        </div>
      </div>
      <HowItWorks />
    </div>
  );
}
