import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { getSessionState, setNewMsgsCount } from "../../../../features/session";
import {
  getLocalDateNonMilitary,
  getMomentDate,
} from "../../../../utils/get-date-pretty";
import { getLastName } from "../../../../utils/patient/get-last-name";
import { ReactComponent as NoShowIcon } from "../../../../Assets/NewIcons/eye.svg";
import "./style.scss";
import { PopupLayerContext } from "../../../NewComponents/Common/PopupLayer";
import { ActionPopupGenerator } from "../../../NewComponents/Common/ActionPopup";
import { NotificationLayerContext } from "../../../NewComponents/Common/NotificationLayer";
import { PinButton } from "../../../NewComponents/Common/PinButton";
import {
  FullName,
  BirthDate,
  Actions,
} from "../../../NewComponents/Common/TableComponents";
import { PatientDashboardItem } from "../../../../types/Dashboard";
import { TableHeadPayload } from "../../../../types/Table/TableHeadPayload";
import { useMarkAsNoShowMutation } from "../../../../features/api/appointments";
import { wait } from "../../../../utils/wait";
import { APPLICATION_START_POINT_TIMESTAMP } from "../../../../constants";
import { USER_ROLES } from "../../../../types/Main";

function MarkAsNoShowButton({
  payload,
  reload,
}: {
  payload: PatientDashboardItem;
  reload: () => Promise<void>;
}) {
  const { showPopup } = useContext(PopupLayerContext);
  const { showSuccess, showError } = useContext(NotificationLayerContext);
  const [markAsNoShow] = useMarkAsNoShowMutation();
  const { userId: providerId } = useSelector(getSessionState);

  return (
    <div
      className="patients-table__action-button"
      onClick={(e) => {
        e.stopPropagation();
        showPopup(
          ActionPopupGenerator({
            title: "Mark patient as a no show?",
            text: `Are you sure you want to mark ${payload.fullName} as a no show patient? Patient's data will be moved to the “Inactive patients” tab.`,
            action: async () => {
              const response: any = await markAsNoShow({
                patientId: payload.patientId,
                userId: providerId,
              });
              if (response.error) {
                showError({
                  title: "Something went wrong...",
                  description: "Unable to mark patient as no show",
                });
                throw new Error("Unable to mark patient as no show");
              }
              await wait(3000);
              reload();
              showSuccess({
                title: "You've successfuly marked patient as a no show!",
                description: "Patient was moved to the inactive patients tab.",
              });
            },
            actionText: "Mark as a no show",
          })
        );
      }}
      data-tooltip="Mark as No Show"
    >
      <NoShowIcon />
    </div>
  );
}

export const tableColumns: TableHeadPayload<PatientDashboardItem>[] = [
  {
    title: "Full name",
    get: (payload) => getLastName(payload.fullName),
    getPretty: (payload) => <FullName payload={payload} />,
    sort: (dir) => [
      {
        filter: {
          bool: {
            must: {
              exists: {
                field: "lastNameNumberValue",
              },
            },
          },
        },
        script_score: {
          script: {
            source: `${
              dir === "asc" ? "1 -" : ""
            } (doc['lastNameNumberValue'].value  / 100000000.0)`,
          },
        },
      },
      {
        filter: {
          match_all: {},
        },
        weight: 1,
      },
    ],
  },
  {
    title: "Date of Birth",
    get: (payload) => payload.dateOfBirth,
    getPretty: (payload) => <BirthDate payload={payload} />,
    sort: (dir) => [
      {
        filter: {
          bool: {
            must: {
              exists: {
                field: "dateOfBirthDate",
              },
            },
          },
        },
        script_score: {
          script: {
            source: `${
              dir === "desc" ? "1 -" : ""
            } doc['dateOfBirthDate'].value / 100000.0`,
          },
        },
      },
      {
        filter: {
          match_all: {},
        },
        weight: 1,
      },
    ],
  },
  {
    title: "Date of Service",
    get: () => 0,
    getPretty: ({ followUp, appointmentDate }) => {
      const dateOfService =
        followUp?.isInitialFollowUp === false
          ? followUp.timestamp
          : appointmentDate;
      return (
        <p>{dateOfService ? getLocalDateNonMilitary(dateOfService) : "-"}</p>
      );
    },
    sort: (dir) => [
      {
        filter: {
          bool: {
            must: [
              {
                exists: {
                  field: "followUp.timestamp",
                },
              },
              {
                match: {
                  "followUp.isInitialFollowUp": false,
                },
              },
            ],
          },
        },
        script_score: {
          script: {
            source: `${
              dir === "desc" ? "1 -" : ""
            } 10 / Math.log((doc['followUp.timestamp'].value - ${APPLICATION_START_POINT_TIMESTAMP}L) / 60000)`,
          },
        },
      },
      {
        filter: {
          bool: {
            must: [
              {
                exists: {
                  field: "appointmentDate",
                },
              },
              {
                bool: {
                  should: [
                    {
                      bool: {
                        must_not: {
                          exists: {
                            field: "followUp",
                          },
                        },
                      },
                    },
                    {
                      match: {
                        "followUp.isInitialFollowUp": true,
                      },
                    },
                  ],
                },
              },
            ],
          },
        },
        script_score: {
          script: {
            source: `${
              dir === "desc" ? "1 -" : ""
            } 10 / Math.log((doc['appointmentDate'].value - ${APPLICATION_START_POINT_TIMESTAMP}L) / 60000)`,
          },
        },
      },
      {
        filter: {
          match_all: {},
        },
        weight: 1,
      },
    ],
  },
  {
    title: "Next Follow-Up",
    get: () => 0,
    getPretty: (payload) => (
      <p>
        {payload.followUp?.nextFollowUpDate
          ? getLocalDateNonMilitary(payload.followUp.nextFollowUpDate)
          : "-"}
      </p>
    ),
    sort: (dir) => [
      {
        filter: {
          bool: {
            must: {
              exists: {
                field: "followUp.nextFollowUpDate",
              },
            },
          },
        },
        script_score: {
          script: {
            source: `${
              dir === "desc" ? "1 -" : ""
            } 10 / Math.log((doc['followUp.nextFollowUpDate'].value - ${APPLICATION_START_POINT_TIMESTAMP}L) / 60000)`,
          },
        },
      },
      {
        filter: {
          match_all: {},
        },
        weight: 1,
      },
    ],
  },
  {
    title: "Actions",
    get: () => "",
    getPretty: (payload, reload, _, updateTable) => (
      <Actions
        payload={payload}
        updateTable={updateTable}
        employeeType={USER_ROLES.PROVIDER}
        reload={reload}
      />
      // <div className="patients-table__actions">
      //  {payload.subscription?.membership ||
      //  ((payload.appointmentStatus === "PENDING" ||
      //    payload.appointmentStatus === "CONFIRMED") &&
      //    (!payload.followUp || payload.followUp.isInitialFollowUp)) ? (
      //    <PinButton employeeType={USER_ROLES.PROVIDER} payload={payload} updateTable={updateTable} />
      //  ) : null}
      //  {(payload.appointmentStatus === "PENDING" ||
      //    payload.appointmentStatus === "CONFIRMED") &&
      //  (!payload.followUp ||
      //    (payload.followUp.isInitialFollowUp &&
      //      !payload.followUp.isTreatmentPlanSet)) &&
      //  payload.appointmentDate &&
      //  payload.appointmentDate < Date.now() ? (
      //    <MarkAsNoShowButton payload={payload} reload={reload} />
      //  ) : null}
      // </div>
    ),
  },
];
