import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { PopupLayerContext } from "../PopupLayer";
import { ActionPopupGenerator } from "../ActionPopup";
import { NotificationLayerContext } from "../NotificationLayer";
import { useArchivePatientMutation } from "../../../../features/api/patients";
import { PatientDashboardItem } from "../../../../types/Dashboard";
import { USER_ROLES } from "../../../../types/Main";
import { PinButton } from "../PinButton";
import { getSessionState } from "../../../../features/session";
import {
  useMarkAsNoShowMutation,
  useConfirmAppointmentMutation,
} from "../../../../features/api/appointments";
import { wait } from "../../../../utils/wait";
import { ReactComponent as ActionOptions } from "../../../../Assets/icons/action-options.svg";
import { ReactComponent as ArchiveAppt } from "../../../../Assets/icons/archive-patient.svg";
import { ReactComponent as CancelAppt } from "../../../../Assets/icons/cancel-appt.svg";
import { ReactComponent as ConfirmAppt } from "../../../../Assets/icons/confirm-appt.svg";
import { ReactComponent as ResceduleAppt } from "../../../../Assets/icons/rescedule-appt.svg";
import { ReactComponent as ConfirmedAppt } from "../../../../Assets/icons/confirmed-appt.svg";
import { ReactComponent as NoShowIcon } from "../../../../Assets/NewIcons/eye.svg";
import { TableOperation } from "../../../../types/Table/TableOperation";
import { EMPLOYEE_ROLES } from "../../../../types/Employee";

function canPinPatient(payload: PatientDashboardItem) {
  return (
    payload.subscription?.membership ||
    ((payload.appointmentStatus === "PENDING" ||
      payload.appointmentStatus === "CONFIRMED") &&
      (!payload.followUp || payload.followUp.isInitialFollowUp))
  );
}

function canMarkAsNoShow(
  payload: PatientDashboardItem,
  employeeType: EMPLOYEE_ROLES
) {
  return (
    [USER_ROLES.ADMINISTRATOR, USER_ROLES.PROVIDER].includes(employeeType) &&
    (payload.appointmentStatus === "PENDING" ||
      payload.appointmentStatus === "CONFIRMED") &&
    (!payload.followUp ||
      (payload.followUp.isInitialFollowUp &&
        !payload.followUp.isTreatmentPlanSet)) &&
    payload.appointmentDate &&
    payload.appointmentDate < Date.now()
  );
}

function ArchiveButton({
  payload,
  reload,
}: {
  payload: PatientDashboardItem;
  reload: () => Promise<void>;
}) {
  const { showPopup } = useContext(PopupLayerContext);
  const { showSuccess, showError } = useContext(NotificationLayerContext);
  const [archivePatient] = useArchivePatientMutation();

  return (
    <div
      // className="patients-table__action-button"
      onClick={(e) => {
        e.stopPropagation();
        showPopup(
          ActionPopupGenerator({
            title: "Archive patient?",
            text: `Are you sure you want to archive ${payload.fullName}? Patient's data will be moved to the “archived” tab.`,
            action: async () => {
              const response: any = await archivePatient(payload.patientId);
              if (response.error) {
                showError({
                  title: "Something went wrong...",
                  description: "Unable to archive patient",
                });
                throw new Error("Unable to archive patient");
              }
              await wait(3000);
              reload();
              showSuccess({
                title: "Success!",
                description: "You've successfuly archived a patient!",
              });
            },
            actionText: "Archive",
          })
        );
      }}
      data-tooltip="Archive"
    >
      <ArchiveAppt />
    </div>
  );
}

function RescheduleAppointmentButton({
  payload,
}: {
  payload: PatientDashboardItem;
}) {
  const history = useHistory();

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        history.push(`/reschedule/${payload.patientId}`);
      }}
      data-tooltip="Reschedule Appointment"
    >
      <ResceduleAppt />
    </div>
  );
}

function CancelAppointmentButton({
  payload,
}: {
  payload: PatientDashboardItem;
}) {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        window.location.assign(
          `https://calendly.com/app/scheduled_events/team/151216?trigger_cancel=${payload.appointmentId}&uuid=${payload.appointmentId}`
        );
      }}
      data-tooltip="Cancel Appointment"
    >
      <CancelAppt />
    </div>
  );
}

function ConfirmAppointmentButton({
  payload,
  reload,
}: {
  payload: PatientDashboardItem;
  reload: () => Promise<void>;
}) {
  const { showPopup } = useContext(PopupLayerContext);
  const { showSuccess, showError } = useContext(NotificationLayerContext);
  const [confirmAppointment] = useConfirmAppointmentMutation();
  const pinnedFlag = "pinnedByAdministrator";
  const pinned = !!payload.flags[pinnedFlag];

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        if (payload.appointmentStatus === "CONFIRMED") return;
        showPopup(
          ActionPopupGenerator({
            title: "Confirm appointment of the patient?",
            text: `Are you sure you want to confirm an appointment of ${payload.fullName}?`,
            action: async () => {
              const response: any = await confirmAppointment(payload.patientId);
              if (response.error) {
                showError({
                  title: "Something went wrong...",
                  description: "Unable to confirm an appointment",
                });
                throw new Error("Unable to confirm an appointment");
              }
              await wait(3000);
              reload();
              showSuccess({
                title: "Success!",
                description: "You've successfuly confirmed an appointment",
              });
            },
            actionText: "Confirm appointment",
          })
        );
      }}
      data-tooltip={
        payload.appointmentStatus === "CONFIRMED"
          ? "Appointment confirmed"
          : "Confirm appointment"
      }
    >
      {payload.appointmentStatus === "CONFIRMED" ? (
        <ConfirmedAppt />
      ) : (
        <ConfirmAppt />
      )}
    </div>
  );
}

// eslint-disable-next-line react/prop-types
function Popover({ onClose, children }) {
  const popoverRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        onClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div
      ref={popoverRef}
      style={{
        backgroundColor: "#FFFFFF",
        width: "525px",
        height: "fit-content",
        zIndex: "99999999",
        padding: "20px 216px 0px 10px",
      }}
    >
      {children}
    </div>
  );
}

function MarkAsNoShowButton({
  payload,
  reload,
}: {
  payload: PatientDashboardItem;
  reload: () => Promise<void>;
}) {
  const { showPopup } = useContext(PopupLayerContext);
  const { showSuccess, showError } = useContext(NotificationLayerContext);
  const [markAsNoShow] = useMarkAsNoShowMutation();
  const { userId: adminId } = useSelector(getSessionState);

  return (
    <div
      className="patients-table__action-button"
      onClick={(e) => {
        e.stopPropagation();
        showPopup(
          ActionPopupGenerator({
            title: "Mark patient as a no show?",
            text: `Are you sure you want to mark ${payload.fullName} as a no show patient? Patient's data will be moved to the “Inactive patients” tab.`,
            action: async () => {
              const response: any = await markAsNoShow({
                patientId: payload.patientId,
                userId: adminId,
              });
              if (response.error) {
                showError({
                  title: "Something went wrong...",
                  description: "Unable to mark patient as no show",
                });
                throw new Error("Unable to mark patient as no show");
              }
              await wait(3000);
              reload();
              showSuccess({
                title: "You've successfuly marked patient as a no show!",
                description: "Patient was moved to the inactive patients tab.",
              });
            },
            actionText: "Mark as a no show",
          })
        );
      }}
      data-tooltip="Mark as No Show"
    >
      <NoShowIcon />
    </div>
  );
}

function ActionItems({
  payload,
  reload,
}: {
  payload: PatientDashboardItem;
  reload: () => Promise<void>;
}) {
  const [showPopover, setShowPopover] = useState(false);
  const handleDropdownClick = (e) => {
    e.stopPropagation();
    setShowPopover(!showPopover);
  };

  const handleClosePopover = () => {
    setShowPopover(false);
  };
  return (
    <div className="patients-table__action-button">
      {!showPopover && (
        <div
          className="action-option__dots-icon"
          data-tooltip="More items"
          onClick={handleDropdownClick}
        >
          <ActionOptions />
        </div>
      )}
      {showPopover && (
        <Popover onClose={handleClosePopover}>
          <div className="action-option__dropdown">
            <ul>
              <li>
                {(payload.appointmentStatus === "PENDING" ||
                  payload.appointmentStatus === "CONFIRMED") &&
                (!payload.followUp ||
                  (payload.followUp.isInitialFollowUp &&
                    !payload.followUp.isTreatmentPlanSet)) ? (
                  <ConfirmAppointmentButton payload={payload} reload={reload} />
                ) : null}
              </li>
              <li>
                {!payload.followUp ||
                (payload.followUp.isInitialFollowUp &&
                  !payload.followUp.isTreatmentPlanSet) ? (
                  <RescheduleAppointmentButton payload={payload} />
                ) : null}
              </li>
              <li>
                {(payload.appointmentStatus === "PENDING" ||
                  payload.appointmentStatus === "CONFIRMED") &&
                (!payload.followUp ||
                  (payload.followUp.isInitialFollowUp &&
                    !payload.followUp.isTreatmentPlanSet)) ? (
                  <CancelAppointmentButton payload={payload} />
                ) : null}
              </li>
              <li>
                <ArchiveButton payload={payload} reload={reload} />
              </li>
            </ul>
          </div>
        </Popover>
      )}
    </div>
  );
}

export function Actions({
  payload,
  employeeType,
  updateTable,
  reload,
}: {
  payload: PatientDashboardItem;
  employeeType: EMPLOYEE_ROLES;
  reload: () => Promise<void>;
  updateTable: (
    operation: TableOperation<PatientDashboardItem>
  ) => Promise<void>;
}) {
  return (
    <div className="patients-table__actions">
      {canPinPatient(payload) ? (
        <PinButton
          employeeType={employeeType}
          updateTable={updateTable}
          payload={payload}
        />
      ) : null}
      {canMarkAsNoShow(payload, employeeType) ? (
        <MarkAsNoShowButton payload={payload} reload={reload} />
      ) : null}
      {employeeType === USER_ROLES.ADMINISTRATOR && !payload.archived ? (
        <ActionItems payload={payload} reload={reload} />
      ) : null}
    </div>
  );
}
