import React, { useEffect } from "react";
import { Box, Typography, Grid, RadioGroup, Radio } from "@mui/material";
import { CustomRadioButton } from "../../../Common/Material/RadioButton";
import { PregnancyWorkPopupSteps } from "../steps";
import { usePregnancyWorkStepper } from "../../../../../contexts/pregnancy-work";

export default function InsuranceSelection() {
  const { handleNext, updateValues, setIsNextDisabled, pregnancyWork } =
    usePregnancyWorkStepper();

  useEffect(() => {
    setIsNextDisabled(!pregnancyWork.paymentMethod);
  }, [pregnancyWork.paymentMethod]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target?.value as "cash" | "with-insurance";
    if (value) {
      updateValues({ paymentMethod: value });
      handleNext();
    }
  };

  return (
    <Grid container direction="column" p={2}>
      <Typography variant="h5" gutterBottom>
        How are you paying for your blood work?
      </Typography>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        onChange={handleChange}
        defaultValue={pregnancyWork.paymentMethod}
      >
        <Grid container columnGap={8}>
          <Grid item xs={12} md={5}>
            <CustomRadioButton
              value="cash"
              control={<Radio />}
              label={
                <Box>
                  <Typography variant="body1">Without insurance</Typography>
                  <Typography variant="subtitle2">
                    Pay directly on Honeydew at discounted rates
                  </Typography>
                </Box>
              }
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <CustomRadioButton
              value="with-insurance"
              control={<Radio />}
              label={
                <Box>
                  <Typography variant="body1">With insurance</Typography>
                  <Typography variant="subtitle2">
                    Subject to your copay and deductible
                  </Typography>
                </Box>
              }
            />
          </Grid>
        </Grid>
      </RadioGroup>
    </Grid>
  );
}
