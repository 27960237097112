import {
  ORDER_COST,
  PHYSICIAN_REQUISITION,
  SERVICE_FEE,
} from "../../app/NewComponents/Common/Payment/PaymentDetails";

export interface PriceDetail {
  test: string;
  price: number;
  walkInPrice: number;
  drawFee: number;
}

export const getChargeItems = ({
  priceDetails,
}: {
  priceDetails: PriceDetail[];
}) => {
  const charges: Array<{ amount: number; description: string }> = [];
  priceDetails.forEach((detail) => {
    charges.push({
      amount: Math.round(detail.price * 100), // in cents
      description: detail.test,
    });
  });
  const labCharges = [
    {
      amount: Math.round(PHYSICIAN_REQUISITION * 100), // in cents
      description: "Physician requisition",
    },
    {
      amount: Math.round(ORDER_COST * 100), // in cents
      description: "Order cost",
    },
    {
      amount: Math.round(priceDetails[0].drawFee * 100), // in cents
      description: "Draw fee",
    },
    {
      amount: Math.round(SERVICE_FEE * 100), // in cents
      description: "Service fee",
    },
  ];

  return [...charges, ...labCharges];
};
