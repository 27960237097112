import React, { useMemo } from "react";
import { Grid, Link, checkboxClasses } from "@mui/material";
import { CustomCheckbox } from "../../Material/Checkbox";
import PaymentMethodButton from "./StripeButton";
import { useMobile } from "../../../../../hooks/use-mobile";
import PriceDetails from "./PriceDetails";
import { SectionTitle, Title } from "../../FindLab/styles";
import { ConsentText, SavingsText, WalkInPrice } from "./styles";
import { parsePrice } from "../../../../../utils/price";

export const PHYSICIAN_REQUISITION = 1;
export const ORDER_COST = 3;
export const SERVICE_FEE = 25;

export default function PaymentDetails({
  consent,
  setConsent,
  handleChangePaymentMethod,
  defaultPaymentMethod,
  handleDefaultPaymentMethodChange,
  lab,
}: any) {
  const { isMobile } = useMobile();

  const totalWalkInPrice = useMemo(() => {
    const walkInSum = lab?.priceDetails.reduce(
      (acc, test) => acc + test.walkInPrice,
      0
    );
    return walkInSum + SERVICE_FEE + PHYSICIAN_REQUISITION + ORDER_COST;
  }, [lab]);

  // only show the savings if the walk in is at least 10% more than the order price
  const showSavings = totalWalkInPrice * 0.9 > lab.totalPrice;

  return (
    <Grid container p={2}>
      <Title gutterBottom>Payment</Title>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={6}>
          <PriceDetails
            orderPrice={lab.totalPrice}
            priceDetails={lab?.priceDetails}
          />
        </Grid>
        {showSavings && (
          <Grid item xs={12} md={6}>
            <WalkInPrice mt={isMobile ? 2 : 0}>
              {`Walk-in prices for these tests is $${parsePrice(
                totalWalkInPrice
              )}.`}
            </WalkInPrice>
            <SavingsText>
              {`You’re saving $${parsePrice(
                totalWalkInPrice - lab.totalPrice
              )} by ordering through Honeydew!`}
            </SavingsText>
          </Grid>
        )}
      </Grid>
      <SectionTitle mt={2} mb={2}>
        PAYMENT METHOD
      </SectionTitle>
      <PaymentMethodButton
        handleDefaultPaymentMethodChange={handleDefaultPaymentMethodChange}
        defaultPaymentMethod={defaultPaymentMethod}
        handleChangePaymentMethod={handleChangePaymentMethod}
      />
      <Grid container alignItems="center" mt={4}>
        <Grid item mr={1}>
          <CustomCheckbox
            sx={{
              [`&, &.${checkboxClasses.checked}`]: {
                color: "common.green",
              },
            }}
            checked={consent}
            onChange={() => setConsent(!consent)}
          />
        </Grid>
        <Grid item xs={11} height="16px">
          <ConsentText>
            {`I consent to telehealth services. `}
            <Link
              href="https://honeydew-prod.s3.amazonaws.com/Telehealth+Consent+Form.pdf"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              Learn more
            </Link>
          </ConsentText>
        </Grid>
      </Grid>
    </Grid>
  );
}
