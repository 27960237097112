import { FEATURES } from "../data/available-features";
import { HONEYDEW_ELIGIBLE_STATES } from "../data/states";

export const testEnvConfig = {
  ENVIRONMENT: (process.env.REACT_APP_ENV as string).toLowerCase(),
  ADD_UNSUPPORTED_VISITOR_URL:
    "https://teakzsii51.execute-api.us-west-1.amazonaws.com/test/api/add-unsupported-visitor",
  PATIENTS_SERVICE_GRAPHQL_URL:
    "https://l2qllkomfretth34s6gnepm4om.appsync-api.us-west-1.amazonaws.com/graphql",
  PAYMENT_SERVICE_GRAPHQL_URL:
    "https://tr3sdqgbavdgjdpns7lgqbxu24.appsync-api.us-west-1.amazonaws.com/graphql",
  REFERRAL_SERVICE_GRAPHQL_URL:
    "https://uruuoc3ijjcrzinwrvwpkh4nny.appsync-api.us-west-1.amazonaws.com/graphql",
  FOLLOW_UP_SERVICE_GRAPHQL_URL:
    "https://pa257axfxvcrtbor7fafbq5a6y.appsync-api.us-west-1.amazonaws.com/graphql",
  APPOINTMENT_SERVICE_GRAPHQL_URL:
    "https://j2ab43h7erbpdi6ufvseattbfi.appsync-api.us-west-1.amazonaws.com/graphql",
  MEDICATIONS_SERVICE_GRAPHQL_URL:
    "https://2pvbeabojvfz7jr4fl3dwrrxxa.appsync-api.us-west-1.amazonaws.com/graphql",
  EMPLOYEES_SERVICE_GRAPHQL_URL:
    "https://i46bqsftgzhyxbo62fgwoypas4.appsync-api.us-west-1.amazonaws.com/graphql",
  EMPLOYEES_SERVICE_REST_URL:
    "https://q73q37drh9.execute-api.us-west-1.amazonaws.com/test",
  MARKETING_SERVICE_GRAPHQL_URL:
    "https://2e2t4k5utzgtrmmhj5o3efjqgy.appsync-api.us-west-1.amazonaws.com/graphql",
  CHATS_SERVICE_GRAPHQL_URL:
    "https://lrwdnzwjlfanzlammqowizay2i.appsync-api.us-west-1.amazonaws.com/graphql",
  LAB_SERVICE_GRAPHQL_URL:
    "https://jsmbj46tsnhobibeo5agfqbtnu.appsync-api.us-west-1.amazonaws.com/graphql",
  QUERY_DASHBOARD_ITEMS_URL:
    "https://3r0kt6f61k.execute-api.us-west-1.amazonaws.com/test",
  AMAZON_API_DOMAIN:
    "https://j405xnzdpi.execute-api.us-east-1.amazonaws.com/test",
  ROUTE_53_BASE_URL: "https://testapi.honeydewcare.com",
  USER_POOL_ID: "us-west-1_X9TG9RHy0",
  CLIENT_ID: "74cs6q73to7qls7aiboi56dft1",
  AWS_REGION: "us-west-1",
  STRIPE_KEY:
    "pk_test_51H22XaB4dX8zjKdO6JGV1C9uMXv9Pj6s01aECFJGDvezblFfs28nEqbWGSL12CTQanBXekmeNYy43inPNtTb8ywp00gCes5p9p",
  ACCUTANE_API_PATH:
    "https://sn4kogdf08.execute-api.us-west-1.amazonaws.com/test/accutane",
  CALENDLY_APPOINTMENT_WIGDET_URLS: {
    [HONEYDEW_ELIGIBLE_STATES.NY]:
      "https://calendly.com/acneaway-test/honeydew-consultation-test-new-york",
    [HONEYDEW_ELIGIBLE_STATES.NJ]:
      "https://calendly.com/acneaway-test/honeydew-consultation-test-new-jersey",
    [HONEYDEW_ELIGIBLE_STATES.FL]:
      "https://calendly.com/acneaway-test/honeydew-consultation-test-florida",
    [HONEYDEW_ELIGIBLE_STATES.CT]:
      "https://calendly.com/acneaway-test/honeydew-consultation-test-connecticut",
    [HONEYDEW_ELIGIBLE_STATES.MI]:
      "https://calendly.com/acneaway-test/honeydew-consultation-test-michigan",
    [HONEYDEW_ELIGIBLE_STATES.WY]:
      "https://calendly.com/acneaway-test/honeydew-consultation-test-wyoming",
    [HONEYDEW_ELIGIBLE_STATES.UT]:
      "https://calendly.com/acneaway-test/honeydew-consultation-test-utah",
    [HONEYDEW_ELIGIBLE_STATES.NV]:
      "https://calendly.com/acneaway-test/honeydew-consultation-test-nevada",
    [HONEYDEW_ELIGIBLE_STATES.RI]:
      "https://calendly.com/acneaway-test/honeydew-consultation-test-rhode-island",
  },
  AMAZON_S3_USER_IMAGES_DOMAIN: "https://db6vo0m5igov1.cloudfront.net",
  BLOOD_SLIP_TEMPLATE_ID: "073f3cc4f11f414283e19a40b66827252efd6122",
  AMAZON_S3_PATIENT_DOCUMENT_DOMAIN:
    "https://honeydew-accutane-service-documents-bucket-test.s3.amazonaws.com",
  PUBNUB_PUBLISH_KEY: "pub-c-aa522941-805f-4945-9b5e-eb07d055db85",
  ENABLED_FEATURES: [FEATURES.VITAL],
};
