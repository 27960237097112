/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
} from "react";
import { BloodWorkPopupSteps } from "../app/NewComponents/Patient/BloodWorkPopup/steps";
import { getNextStep, getPreviousStep } from "../utils/blood-work-stepper";
import { BloodWorkForm } from "../types/BloodWorkForm";

type BloodWorkStepper = {
  handleNext: (nextStep?: number) => void;
  handleBack: (prevStep?: number) => void;
  updateValues: (form: Partial<BloodWorkForm>) => void;
  setIsNextDisabled: (value: boolean) => void;
  setCloseFunction: (close: () => void) => void;
  setCurrentStep: (step: number) => void;
  cleanup: () => void;
  onClose?: () => void;
  currentStep: number;
  bloodwork: BloodWorkForm;
  isNextDisabled: boolean;
};

const DEFAULT_VALUE = {
  handleNext: () => {},
  handleBack: () => {},
  updateValues: () => {},
  setIsNextDisabled: () => {},
  setCloseFunction: () => {},
  setCurrentStep: () => {},
  cleanup: () => {},
  onClose: () => {},
  currentStep: 0,
  bloodwork: {},
  isNextDisabled: false,
};

const BloodWorkStepperContext = createContext<BloodWorkStepper>(DEFAULT_VALUE);

export function BloodWorkStepperProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [currentStep, setCurrentStep] = useState(BloodWorkPopupSteps.WELCOME);
  const [bloodwork, setBloodwork] = useState<BloodWorkForm>({});
  const [onClose, setOnClose] = useState<() => void>();
  const [isNextDisabled, setIsNextDisabled] = useState(false);

  const cleanup = () => {
    setCurrentStep(BloodWorkPopupSteps.WELCOME);
    setBloodwork({});
    setIsNextDisabled(false);
  };

  useEffect(() => () => cleanup(), []); // cleanup when unmounted

  const handleNext = (nextStep?: number) => {
    if (
      currentStep === BloodWorkPopupSteps.CHECKOUT ||
      currentStep === BloodWorkPopupSteps.CHECKOUT_INSURANCE
    ) {
      cleanup();
      onClose?.();
    } else if (nextStep) {
      setCurrentStep(nextStep);
    } else {
      setCurrentStep(getNextStep(currentStep));
    }
  };

  const handleBack = (prevStep?: number) => {
    if (currentStep === BloodWorkPopupSteps.WELCOME) {
      cleanup();
      onClose?.();
    } else if (prevStep) {
      setCurrentStep(prevStep);
    } else {
      setCurrentStep(getPreviousStep(currentStep));
    }
  };

  const setCloseFunction = (close: () => void) => {
    setOnClose(close);
  };

  const updateValues = (form: BloodWorkForm) => {
    setBloodwork({ ...bloodwork, ...form });
  };

  const value = useMemo(
    () => ({
      handleNext,
      handleBack,
      updateValues,
      setIsNextDisabled,
      setCloseFunction,
      setCurrentStep,
      cleanup,
      onClose,
      currentStep,
      bloodwork,
      isNextDisabled,
    }),
    [currentStep, bloodwork, isNextDisabled, onClose]
  );
  return (
    <BloodWorkStepperContext.Provider value={value}>
      {children}
    </BloodWorkStepperContext.Provider>
  );
}

export const useBloodWorkStepper = () => useContext(BloodWorkStepperContext);
