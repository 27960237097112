import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isNull } from "util";
import { getSessionState, setNewMsgsCount } from "../../../../features/session";
import {
  DoctorGreetingBanner,
  TaskColors,
} from "../../../NewComponents/Common/DoctorGreetingBanner";
import "./style.scss";
import { useGetEmployeeQuery } from "../../../../features/api/employees";
import { Provider } from "../../../../types/Employee";
import { PatientsTableElastic } from "../../../NewComponents/Common/PatientsTableElastic";
import { PatientDashboardItem } from "../../../../types/Dashboard";
import { Stylings } from "../../../../types/Table/TableBodyPayload";
import { patientDashboardForAdministratorSort } from "./sortings";
import { PatientsTableTabs } from "../../../NewComponents/Common/PatientsTableElastic/tableTabs";
import {
  getPatientDashboardForProviderFilters,
  getPatientDashboardForProviderFiltersInactive,
} from "./filters";
import { getTwilioState } from "../../../../features/twilio";
import { patientDashboardForAdministratorOrder } from "./orders";
import { getAllChannelMetadata } from "../../../../utils/patient/pubnubChatCount";
import { tableColumns } from "./columns";
import { fetchChatData } from "../../../../utils/employee/fetch-chat-data";
import { HoneydewAPI } from "../../../../services/honeydew-api";

function getStyleForDashboard(employeeId: string) {
  return (payload: PatientDashboardItem, currentFilter?: string) => {
    switch (true) {
      case currentFilter === "New follow-ups":
        return Stylings.Blue;
      case currentFilter === "Miscellaneous tasks":
        return Stylings.Orange;
      case currentFilter === "New patients":
        return Stylings.Red;
      case payload.followUp &&
        !payload.followUp.isInitialFollowUp &&
        !payload.followUp.isTreatmentPlanSet:
        return Stylings.Blue;
      case payload.tasks?.includes(employeeId):
        return Stylings.Orange;
      case !payload.followUp?.isTreatmentPlanSet &&
        (payload.appointmentStatus === "PENDING" ||
          payload.appointmentStatus === "CONFIRMED"):
        return Stylings.Red;

      default:
        return Stylings.Base;
    }
  };
}

const tabs = ["Active Patients", "Inactive Patients"];

export function PatientsPageForProvider() {
  const dispatch = useDispatch();
  const { initialized } = useSelector(getTwilioState);
  const [newMessagesCount, setNewMessagesCount] = useState<number | null>(null);
  const history = useHistory();
  const [tab, setTab] = useState(0);
  const [tableParams, setTableParams] = useState<
    { filters: any; sort: any; order: any }[]
  >([]);
  const {
    userId: providerId,
    userRole,
    newMsgsCount,
  } = useSelector(getSessionState);
  const providerQuery = useGetEmployeeQuery(providerId as string, {
    skip: !providerId,
  });
  const provider = providerQuery.data as Provider | undefined;
  const [pubnub, setPubNub] = useState<PubNub | null>(null);

  const [filterArray, setFilterArray] = useState([]);
  const [filteredChat, setFilteredChat] = useState<any>([]);
  const [patientIds, setPatientIds] = useState<any>([]);

  useEffect(() => {
    const role = providerQuery?.currentData?.role;
    if (filterArray.length === 0 && role) {
      let newFilterArray;
      switch (role) {
        case "provider":
          newFilterArray = getPatientDashboardForProviderFilters(
            providerId as string
          );
          break;
        default:
          break;
      }
      if (newFilterArray) {
        setFilterArray(newFilterArray);
      }
    }
  }, [filterArray, providerQuery, providerId]);

  // const filterChatBydata = async (data: any) => {
  //   const updatedChats = data.map((item: any) => {
  //     const {
  //       _source: { chat, latestChatTimestamp },
  //     } = item;

  //     if (chat && Array.isArray(chat)) {
  //       // Extract userId from each chat entry and remove duplicates
  //       const authorIds = [...new Set(chat.map((entry: any) => entry.userId))];

  //       item._source.authorIds = authorIds;
  //       item._source.lastUpdated = latestChatTimestamp;
  //     }

  //     return item;
  //   });
  //   dispatch(setNewMsgsCount(updatedChats.length));
  // };

  useEffect(() => {
    if (filterArray.length > 0) {
      const fetchData = async () => {
        const { filter } = filterArray[0];
        let mustQuery = filter.must;
        if (mustQuery instanceof Array) {
          mustQuery = [...mustQuery];
        } else if (mustQuery instanceof Object) {
          mustQuery = [mustQuery];
        } else {
          mustQuery = [];
        }
        try {
          const query = {
            query: {
              from: 0,
              size: 10000,
              query: {
                // when you comment this query the code starts working with extra records
                function_score: {
                  query: {
                    bool: {
                      ...filter,
                    },
                  },
                },
              },
            },
          };
          const results = await HoneydewAPI.dashboard.queryDashboardItems(
            query
          );
          const filteredChats = results.hits.filter((result: any) => {
            if (!result._source.subscription) {
              return true;
            }

            return result._source.subscription.membership === true;
          });
          setFilteredChat(filteredChats);
          setPatientIds(filteredChats.map((chat) => chat._source.patientId));
        } catch (error) {
          console.log("error in fetching table data", error);
        }
      };
      fetchData();
    }
  }, [filterArray]);

  useEffect(() => {
    if (filteredChat.length && !newMsgsCount && patientIds.length > 0) {
      const fetchData = async () => {
        try {
          const pubNubSubscribeKey =
            await HoneydewAPI.chats.pubNubSubscribeKey();
          const metadataFlags = await getAllChannelMetadata(
            providerId,
            patientIds,
            pubNubSubscribeKey
          );
          const uniqueMetadataFlags = metadataFlags.reduce((acc, obj) => {
            acc[obj.id] = obj;
            return acc;
          }, {});
          const uniqueMetadataArray = Object.values(uniqueMetadataFlags);

          const newMsgs = uniqueMetadataArray.filter(
            (obj) => obj.custom[providerId] === false
          );
          const filteredResult = filteredChat.filter((chat) => {
            const chatMetaData = newMsgs.find(
              (metaData) => metaData.id === chat._source.patientId
            );
            if (chatMetaData) {
              return true;
            }
            return false;
          });
          dispatch(setNewMsgsCount(filteredResult.length));
          // setNewMessagesCount(newMsgs?.length);
          // dispatch(setNewMsgsCount(newMsgs?.length));
        } catch (error) {
          // Handle error here if needed
          console.error("Error fetching metadata flags:", error);
        }
      };

      fetchData();
    }
  }, [filteredChat, patientIds]);

  useEffect(() => {
    if (!providerId) return;
    setTableParams([
      {
        filters: getPatientDashboardForProviderFilters(providerId as string),
        sort: patientDashboardForAdministratorSort(),
        order: patientDashboardForAdministratorOrder(providerId as string),
      },
      {
        filters: getPatientDashboardForProviderFiltersInactive(
          providerId as string
        ),
        sort: [],
        order: [],
      },
    ]);
  }, [providerId]);

  useEffect(() => {
    const currentIndex = localStorage.getItem("currentIndex");
    const currentIndexFormatted = JSON.parse(currentIndex);
    if (!isNull(currentIndexFormatted)) {
      setTab(currentIndexFormatted.currentTab);
    }
  }, []);

  if (!provider) return null;

  const providerName = provider
    ? [provider.firstName, provider.lastName, provider.title]
    : null;

  function renderTable() {
    if (!tableParams[tab]) return null;
    switch (tab) {
      case 0:
        return (
          <PatientsTableElastic<PatientDashboardItem>
            filters={tableParams[tab].filters}
            sort={tableParams[tab].sort}
            order={tableParams[tab].order}
            columns={tableColumns}
            stylizeRow={getStyleForDashboard(provider?.id as string)}
            onClick={(item, state) => {
              window.scrollTo(0, 0);
              const myState = { currentSubTab: state, currentTab: tab };
              localStorage.setItem("currentIndex", JSON.stringify(myState));
              history.push(`/${item.patientId}`);
            }}
          />
        );
      case 1:
        return (
          <PatientsTableElastic<PatientDashboardItem>
            filters={tableParams[tab].filters}
            sort={tableParams[tab].sort}
            order={tableParams[tab].order}
            columns={tableColumns}
            onClick={(item, state) => {
              window.scrollTo(0, 0);
              const myState = { currentSubTab: state, currentTab: tab };
              localStorage.setItem("currentIndex", JSON.stringify(myState));
              history.push(`/${item.patientId}`);
            }}
          />
        );
      default:
        return null;
    }
  }

  return (
    <div className="greeting-container">
      <DoctorGreetingBanner
        items={[
          {
            text: "New patients",
            filter: getPatientDashboardForProviderFilters(provider?.id).find(
              ({ text }) => text === "New patients"
            )?.filter,
            color: TaskColors.Pink,
          },
          {
            text: "Follow-ups",
            filter: getPatientDashboardForProviderFilters(provider?.id).find(
              ({ text }) => text === "New follow-ups"
            )?.filter,
            color: TaskColors.LightBlue,
          },
          {
            text: "New messages",
            count: newMsgsCount ?? undefined,
            color: TaskColors.Green,
          },
          {
            text: "Miscellaneous tasks",
            filter: getPatientDashboardForProviderFilters(provider?.id).find(
              ({ text }) => text === "Miscellaneous tasks"
            )?.filter,
            color: TaskColors.Orange,
          },
        ]}
        name={providerName ? providerName.join(" ") : null}
      />
      <div className="patients-table">
        <div className="patients-table__tabs">
          <PatientsTableTabs
            tabs={tabs}
            onChange={(state) => {
              setTab(state);
            }}
          />
        </div>
      </div>
      {renderTable()}
    </div>
  );
}
