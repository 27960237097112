/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
} from "react";
import { getNextStep, getPreviousStep } from "../utils/pregnancy-work-stepper";
import { PregnancyWorkForm } from "../types/PregnancyWorkForm";
import { PregnancyWorkPopupSteps } from "../app/NewComponents/Patient/PregnancyTestPopup/steps";

type PregnancyWorkStepper = {
  handleNext: (nextStep?: number) => void;
  handleBack: (prevStep?: number) => void;
  updateValues: (form: Partial<PregnancyWorkForm>) => void;
  setIsNextDisabled: (value: boolean) => void;
  setCloseFunction: (close: () => void) => void;
  setCurrentStep: (step: number) => void;
  cleanup: () => void;
  onClose: () => void;
  currentStep: number;
  pregnancyWork: PregnancyWorkForm;
  isNextDisabled: boolean;
  labOrder: any;
};

const DEFAULT_VALUE = {
  handleNext: () => {},
  handleBack: () => {},
  updateValues: () => {},
  setIsNextDisabled: () => {},
  setCloseFunction: () => {},
  cleanup: () => {},
  setCurrentStep: () => {},
  onClose: () => {},
  currentStep: 0,
  pregnancyWork: {},
  isNextDisabled: false,
  labOrder: undefined,
};

const PregnancyWorkStepperContext =
  createContext<PregnancyWorkStepper>(DEFAULT_VALUE);

export function PregnancyWorkStepperProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [currentStep, setCurrentStep] = useState(
    PregnancyWorkPopupSteps.WELCOME
  );
  const [pregnancyWork, setPregnancyWork] = useState<PregnancyWorkForm>({});
  const [onClose, setOnClose] = useState<() => void>();
  const [isNextDisabled, setIsNextDisabled] = useState(false);

  const cleanup = () => {
    setCurrentStep(PregnancyWorkPopupSteps.WELCOME);
    setPregnancyWork({});
    setIsNextDisabled(false);
  };

  useEffect(() => () => cleanup(), []); // cleanup when unmounted

  const handleNext = (nextStep?: number) => {
    if (
      currentStep === PregnancyWorkPopupSteps.CHECKOUT ||
      currentStep === PregnancyWorkPopupSteps.MEDICAL_OFFICE ||
      currentStep === PregnancyWorkPopupSteps.INSURANCE_LAB
    ) {
      cleanup();
      onClose?.();
    } else if (nextStep) {
      setCurrentStep(nextStep);
    } else {
      setCurrentStep(getNextStep(currentStep));
    }
  };

  const handleBack = (prevStep?: number) => {
    if (currentStep === PregnancyWorkPopupSteps.WELCOME) {
      cleanup();
      onClose?.();
    } else if (prevStep) {
      setCurrentStep(prevStep);
    } else {
      setCurrentStep(getPreviousStep(currentStep));
    }
  };

  const setCloseFunction = (close: () => void) => {
    setOnClose(close);
  };

  const updateValues = (form: PregnancyWorkForm) => {
    setPregnancyWork({ ...pregnancyWork, ...form });
  };

  const value = useMemo(
    () => ({
      handleNext,
      handleBack,
      updateValues,
      setIsNextDisabled,
      setCloseFunction,
      setCurrentStep,
      cleanup,
      onClose,
      currentStep,
      pregnancyWork,
      isNextDisabled,
    }),
    [currentStep, pregnancyWork, isNextDisabled, onClose]
  );
  return (
    <PregnancyWorkStepperContext.Provider value={value}>
      {children}
    </PregnancyWorkStepperContext.Provider>
  );
}

export const usePregnancyWorkStepper = () =>
  useContext(PregnancyWorkStepperContext);
