import React, { createContext, useContext, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { ENV_CONFIG } from "../config";
import { getSessionState } from "../features/session";

type FeatureToggleContext = {
  isFeatureEnabled: (feature: string) => boolean;
};

const DEFAULT_VALUE = {
  isFeatureEnabled: () => false,
};

const FeatureToggleContext = createContext<FeatureToggleContext>(DEFAULT_VALUE);

export function FeatureToggleProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [config] = useState(ENV_CONFIG.ENABLED_FEATURES);
  const { activePatientId } = useSelector(getSessionState);

  const isFeatureEnabled = (feature: string) => {
    const userEnabled = ENV_CONFIG.ENABLED_IDS
      ? ENV_CONFIG.ENABLED_IDS.includes(activePatientId ?? "")
      : true;

    console.log(
      "enabled",
      config.includes(feature) && userEnabled,
      activePatientId
    );

    return config.includes(feature) && userEnabled;
  };

  const contextValue = useMemo(
    () => ({ isFeatureEnabled }),
    [isFeatureEnabled]
  );

  return (
    <FeatureToggleContext.Provider value={contextValue}>
      {children}
    </FeatureToggleContext.Provider>
  );
}

export const useFeatureToggle = () => useContext(FeatureToggleContext);
