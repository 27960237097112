import { HONEYDEW_ELIGIBLE_STATES } from "../data/states";
import { FEATURES } from "../data/available-features";

export const devEnvConfig = {
  ENVIRONMENT: (process.env.REACT_APP_ENV as string).toLowerCase(),
  ADD_UNSUPPORTED_VISITOR_URL:
    "https://3iu0uwjcnl.execute-api.us-west-2.amazonaws.com/dev/api/add-unsupported-visitor",
  PATIENTS_SERVICE_GRAPHQL_URL:
    "https://6y2ed4fbsjcn3nijzcyn67vsnm.appsync-api.us-west-2.amazonaws.com/graphql",
  PAYMENT_SERVICE_GRAPHQL_URL:
    "https://wfgdlkcmebcvxox22kukmisk2q.appsync-api.us-west-2.amazonaws.com/graphql",
  REFERRAL_SERVICE_GRAPHQL_URL:
    "https://jxmrhhzasjgrpo5iwkvaopfkra.appsync-api.us-west-2.amazonaws.com/graphql",
  FOLLOW_UP_SERVICE_GRAPHQL_URL:
    "https://b4fkjre5r5bg7hlhprb4exe6ze.appsync-api.us-west-2.amazonaws.com/graphql",
  APPOINTMENT_SERVICE_GRAPHQL_URL:
    "https://j5udsqjtsvgvbfqbataqm6b2lm.appsync-api.us-west-2.amazonaws.com/graphql",
  MEDICATIONS_SERVICE_GRAPHQL_URL:
    "https://4yq4r4g23ragzbpofq4quik5ia.appsync-api.us-west-2.amazonaws.com/graphql",
  EMPLOYEES_SERVICE_GRAPHQL_URL:
    "https://buxsxwc6l5gy5idho3mfy22esy.appsync-api.us-west-2.amazonaws.com/graphql",
  EMPLOYEES_SERVICE_REST_URL:
    "https://b5lyj8qgua.execute-api.us-west-2.amazonaws.com/dev",
  CHATS_SERVICE_GRAPHQL_URL:
    "https://tag2bdmnnrfsvkbnceirxewnke.appsync-api.us-west-2.amazonaws.com/graphql",
  LAB_SERVICE_GRAPHQL_URL:
    "https://iic5y2ttszadjkndg4nhemo2d4.appsync-api.us-west-2.amazonaws.com/graphql",
  QUERY_DASHBOARD_ITEMS_URL:
    "https://c4cd4gn4o0.execute-api.us-west-2.amazonaws.com/dev",
  MARKETING_SERVICE_GRAPHQL_URL: "",
  AMAZON_API_DOMAIN:
    "https://j405xnzdpi.execute-api.us-east-1.amazonaws.com/test",
  ROUTE_53_BASE_URL: "https://testapi.honeydewcare.com",
  USER_POOL_ID: "us-west-2_bj3h6Z7HW",
  AWS_REGION: "us-west-2",
  CLIENT_ID: "659accu2jvibs26np8ipj3nnuk",
  STRIPE_KEY:
    "pk_test_51H22XaB4dX8zjKdO6JGV1C9uMXv9Pj6s01aECFJGDvezblFfs28nEqbWGSL12CTQanBXekmeNYy43inPNtTb8ywp00gCes5p9p",
  ACCUTANE_API_PATH:
    "https://ewr053ojo9.execute-api.us-west-2.amazonaws.com/dev/accutane",
  CALENDLY_APPOINTMENT_WIGDET_URLS: {
    [HONEYDEW_ELIGIBLE_STATES.NY]:
      "https://calendly.com/acneaway-test/honeydew-consultation-test-new-york",
    [HONEYDEW_ELIGIBLE_STATES.NJ]:
      "https://calendly.com/acneaway-test/honeydew-consultation-test-new-jersey",
    [HONEYDEW_ELIGIBLE_STATES.FL]:
      "https://calendly.com/acneaway-test/honeydew-consultation-test-florida",
    [HONEYDEW_ELIGIBLE_STATES.CT]:
      "https://calendly.com/acneaway-test/honeydew-consultation-test-connecticut",
    [HONEYDEW_ELIGIBLE_STATES.MI]:
      "https://calendly.com/acneaway-test/honeydew-consultation-test-michigan",
    [HONEYDEW_ELIGIBLE_STATES.WY]:
      "https://calendly.com/acneaway-test/honeydew-consultation-test-wyoming",
    [HONEYDEW_ELIGIBLE_STATES.UT]:
      "https://calendly.com/acneaway-test/honeydew-consultation-test-utah",
    [HONEYDEW_ELIGIBLE_STATES.NV]:
      "https://calendly.com/acneaway-test/honeydew-consultation-test-nevada",
    [HONEYDEW_ELIGIBLE_STATES.RI]:
      "https://calendly.com/acneaway-test/honeydew-consultation-test-rhode-island",
  },
  AMAZON_S3_USER_IMAGES_DOMAIN: "https://d3ed0j4q1ouzhp.cloudfront.net",
  BLOOD_SLIP_TEMPLATE_ID: "073f3cc4f11f414283e19a40b66827252efd6122",
  AMAZON_S3_PATIENT_DOCUMENT_DOMAIN:
    "https://honeydew-accutane-service-documents-bucket-dev.s3.us-west-2.amazonaws.com",
  PUBNUB_PUBLISH_KEY: "pub-c-1c8173e8-35c5-4d05-868b-b1c5c9e9e878",
  ENABLED_FEATURES: [FEATURES.VITAL],
  // ENABLED_IDS: ["1cd7192b-ced6-4deb-93b0-9e051d3f1804"],
};
