import React from "react";
import { useHistory } from "react-router-dom";
import { SurveyPage } from "../../../../types/CustomSurvey";
import { CustomSurvey } from "../../../NewComponents/Common/CustomSurvey";
import "./style.scss";
import { CalendlySurveyQuestion } from "./survey-questions/calendly";
import { useGetPatientByIdQuery } from "../../../../features/api/patients";
import { Loader } from "../../../NewComponents/Common/Loader";

export function SubmitAppointmentPage({ patientId }: { patientId: string }) {
  const history = useHistory();
  const patientQuery = useGetPatientByIdQuery(patientId);

  if (!patientQuery.isSuccess) {
    return (
      <div className="main-area__loader-wrap">
        <div className="main-area__loader">
          <Loader />
        </div>
      </div>
    );
  }

  const surveyQuestions: SurveyPage[] = [
    {
      isFilled: () => false,
      Component: CalendlySurveyQuestion,
    },
  ];

  return (
    <CustomSurvey
      title="SCHEDULE YOUR INITIAL VISIT"
      questions={surveyQuestions}
      data={{
        patient: patientQuery.data,
      }}
      onComplete={() => {
        if (patientQuery.data.medicalBackground?.sex) history.push("/");
        else history.push("/skin-survey");

        return true;
      }}
    />
  );
}
