import { v4 as uuidv4 } from "uuid";
import { Dispatch } from "@reduxjs/toolkit";
import { pubnubClient } from "../../services/pubnub";
import { setNewMsgsCount } from "../../features/session";

export function pubnubChatCount(
  pubNubSubscribeKey: string,
  pubnubToken: any,
  accId: string,
  userId: string,
  patientId: string,
  userRole: string,
  dispatch: Dispatch<any>,
  newMsgsCount: number | null
) {
  const pubnub = pubnubClient({
    userId: patientId,
    pubNubSubscribeKey,
    pubnubToken,
  });
  async function setChannelMetadata() {
    const { data } = await pubnub.objects.getChannelMetadata({
      channel: patientId,
    });
    if (data) {
      try {
        const newData = { ...data };
        if (newData.custom && newData.custom[userId] === false) {
          newData.custom[userId] = true;
          dispatch(setNewMsgsCount(newMsgsCount - 1));
          await pubnub.objects.setChannelMetadata(
            {
              channel: patientId,
              data: newData,
            },
            (status: any, resp: any) => {
              console.log("status", status);
              console.log("res", resp);
            }
          );
        }
      } catch (error) {
        console.error("Error setting channel metadata:", error);
      }
    }
    // setChannelMetadata();
  }

  setChannelMetadata();
}

export async function getAllChannelMetadata(
  userId,
  patientIds,
  pubNubSubscribeKey
) {
  const pubnub = await pubnubClient({
    userId,
    pubNubSubscribeKey,
  });

  if (pubnub) {
    try {
      let allMetadata = []; // Initialize an array to store all metadata
      const chunkSize = 100;

      // Split patientIds into chunks of 100
      const chunks = [];
      for (let i = 0; i < patientIds.length; i += chunkSize) {
        chunks.push(patientIds.slice(i, i + chunkSize));
      }

      // Fetch metadata for each chunk
      // eslint-disable-next-line no-restricted-syntax
      for (let j = 0; j < chunks.length; j++) {
        const chunk = chunks[j];
        const filterExpression = chunk
          .map((id) => `id == "${id}"`)
          .join(" || ");
        // eslint-disable-next-line no-await-in-loop
        const response = await pubnub.objects.getAllChannelMetadata({
          include: { totalCount: true, customFields: true },
          limit: chunkSize,
          sort: { updated: "desc" },
          filter: filterExpression,
        });

        allMetadata = allMetadata.concat(response.data);
      }

      // Return allMetadata after all chunks have been processed
      return allMetadata;
    } catch (error) {
      console.error("Error getting all channel metadata:", error);
      throw error;
    }
  }

  return []; // Return an empty array if pubnub is not available
}

export async function getTimeOfLatestMsg(userId, pubnub) {
  try {
    if (pubnub) {
      const latestMessageResponse = await pubnub.fetchMessages({
        channels: [userId],
        count: 1,
        includeMessageActions: true,
      });

      const latestMessage =
        latestMessageResponse?.channels?.[userId]?.[0]?.message;
      if (latestMessage && latestMessage.timestamp) {
        return latestMessage.timestamp;
      }
      console.warn("No messages found or message timestamp missing");
      return null;
    }
    console.error("Pubnub client initialization failed");
    return null;
  } catch (error) {
    console.error("Error fetching messages:", error);
    return null;
  }
}

// export async function pubnubMsgsCountByRole() {
//   const pubnub = await pubnubClient({
//     accountId: uuidv4(),
//   });
//   // try {
//   //   const channelIds = await pubnub.objects.getAllChannelMetadata({
//   //     includeCustom: true,
//   //   });
//   //   if (channelIds) {
//   //     const pubnubChannels = channelIds?.data.map((data: any) => data.id);
//   //     if (pubnubChannels) {
//   //       const metadataPromises = pubnubChannels.map(async (id: string) => {
//   //         const metadata = await pubnub.objects.getChannelMetadata({
//   //           channel: id,
//   //         });
//   //         return metadata;
//   //       });

//   //       const allMetadata = await Promise.all(metadataPromises);
//   //       const metadataFlags = allMetadata.map((obj) => obj?.data?.custom);
//   //       return metadataFlags; // Return metadataFlags array
//   //     }
//   //   }
//   //   console.log("metadata success");
//   // } catch (error) {
//   //   console.log("meta error", error);
//   //   throw error; // Rethrow error to handle it outside
//   // }
// }
