import React, { useEffect } from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ThemeProvider } from "@mui/material";
import { LogInPage } from "./app/Pages/Common/LogInPage";
import { AdministratorView } from "./app/views/AdministratorView";
import { PatientView } from "./app/NewViews/Patient";
// import { ProviderView } from "./app/views/ProviderView";
import { CareCoordinatorView } from "./app/views/CareCoordinatorView";
import { ImageViewPopup } from "./app/Components/Common/ImageViewPopup";
import { ErrorNotificationPopup } from "./app/Components/Common/ErrorNotificationPopup";
import { getSessionState, checkSession } from "./features/session";
import PrivateRoute from "./app/PrivateRoute";
import "./App.css";
import "./styles/common.scss";
import { LogoutTimer } from "./app/Components/Common/LogoutTimer";
import { DeleteConfirmationPopup } from "./app/Components/Common/DeleteConfirmationPopup";
import { AuthenticationService } from "./services/cognito";
import { logInfo } from "./shared/logger";
import EnrollmentCoordinatorView from "./app/views/EnrollmentCoordinatorView";
import { PopupLayer } from "./app/NewComponents/Common/PopupLayer";
import { NotificationLayer } from "./app/NewComponents/Common/NotificationLayer";
import { RegistrationPage } from "./app/NewPages/Patient/Registration";
import { theme } from "./config/theme";
import ProviderView from "./app/views/ProviderView";
import { FeatureToggleProvider } from "./contexts/feature-toggle";
import { BloodWorkStepperProvider } from "./contexts/blood-work";
import { PregnancyWorkStepperProvider } from "./contexts/pregnancy-work";
import { HotReloadProvider } from "./contexts/hot-reload";

function App() {
  const dispatch = useDispatch();
  const { userRole, isSessionExists, isSessionChecked } =
    useSelector(getSessionState);
  const location = useLocation();
  const history = useHistory();
  history.listen(() => {
    window.scrollTo(0, 0);
  });

  const sessionCheck = async () => {
    try {
      const result = await AuthenticationService.checkSession();
      dispatch(checkSession(result));
    } catch (e) {
      logInfo("Session not found");
      dispatch(checkSession(null));
    }
    return null;
  };

  useEffect(() => {
    if (!isSessionChecked) {
      sessionCheck();
    }
  }, []);

  const renderUserView = () => {
    switch (userRole) {
      case "enrollment-coordinators":
        return <EnrollmentCoordinatorView />;
      case "care-coordinators":
        return <CareCoordinatorView />;
      case "providers":
        return <ProviderView />;
      case "admins":
        return <AdministratorView />;
      case "patients":
        return <PatientView />;
      default:
        return <p>{userRole}</p>;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <HotReloadProvider>
        <ErrorNotificationPopup />
        <NotificationLayer>
          <FeatureToggleProvider>
            <BloodWorkStepperProvider>
              <PregnancyWorkStepperProvider>
                <PopupLayer>
                  <DeleteConfirmationPopup>
                    <ImageViewPopup>
                      {isSessionChecked ? (
                        <Switch location={location}>
                          <Route path="/login">
                            <LogInPage />
                          </Route>
                          <Route path="/registration">
                            <RegistrationPage />
                          </Route>
                          <PrivateRoute session={isSessionExists}>
                            <LogoutTimer />
                            {renderUserView()}
                          </PrivateRoute>
                        </Switch>
                      ) : null}
                    </ImageViewPopup>
                  </DeleteConfirmationPopup>
                </PopupLayer>
              </PregnancyWorkStepperProvider>
            </BloodWorkStepperProvider>
          </FeatureToggleProvider>
        </NotificationLayer>
      </HotReloadProvider>
    </ThemeProvider>
  );
}

export default App;
